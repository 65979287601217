import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';
import { FormattedNumber } from 'react-intl';
import { getAgreementStyle, getChainStyle } from '../common/Helpers';
import LedgerTag from '../lib/LedgerTag';
import XBadge from '../lib/XBadge';
import CardTitleContainer from '../lib/CardTitleContainer';


const ValidatorInfo = (props) => {
	const { validator, error } = props;

	if (error) {
		return <div className="error">{error}</div>
	}

	return (
	<div>
		<Card>
			<Card.Body>
				<Card.Title>
					<CardTitleContainer icon="server" title="Validator summary" />
				</Card.Title>
				<Row>
					<Col xs={12} md={7}>
						<Table responsive>
							<thead>
								<tr>
									<th>Public key:</th>
									<th>
										<Link to={`/validator/${validator.validation_public_key}`}>{validator.validation_public_key}</Link>{' '}
										{ validator.unl &&
											<XBadge variant="info">dUNL</XBadge>
										}
									</th>
								</tr>
							</thead>
							<tbody>
								{ validator.domain &&
								<tr>
									<td>Domain:</td>
									<td>
										<span className="hash">
											<a href={`https://${validator.domain}`} target="_blank" rel="noopener noreferrer nofollow">
												{validator.domain}
											</a>
										</span>
									</td>
								</tr>
								}
								<tr>
									<td>Chain:</td>
									<td>
										{validator.chain ?
										<XBadge variant={getChainStyle(validator.chain)} className="text-uppercase">{validator.chain}</XBadge>
										: <mark>UNAVAILABLE</mark>
										}
									</td>
								</tr>
								<tr>
									<td>At ledger:</td>
									<td>
									{validator.chain === 'main' ?
										<LedgerTag ledger_index={validator.current_index} /> :
										<span className="hash"><mark><FormattedNumber value={validator.current_index} /></mark></span>
									}{' '}
									{validator.partial && 
										<Badge variant="danger">PARTIAL</Badge>
									}					
									</td>
								</tr>
							</tbody>
						</Table>
					</Col>

					<Col xs={12} md={5}>
						<Table responsive>
							{validator.agreement_1h && validator.agreement_1h.score &&
							<>
							<thead>
							<tr className={getAgreementStyle(validator.agreement_1h.score, 'table-')}>
								<th>Agreement (1h):</th>
								<th>
									{validator.agreement_1h.score ?
									<span className="hash">
										<FormattedNumber value={validator.agreement_1h.score * 100} maximumFractionDigits={2} />%
									</span>
									: <mark>UNAVAILABLE</mark>
									}
								</th>
							</tr>
						</thead>
						<tbody>
								<tr>
									<td>Ledgers missed (of Total):</td>
									<td>
										<span className="hash">
											{validator.agreement_1h.missed > 0 ?
												<Badge variant="warning"><FormattedNumber value={validator.agreement_1h.missed} /></Badge> :
												<FormattedNumber value={validator.agreement_1h.missed} />
											} of <FormattedNumber value={validator.agreement_1h.total} />
										</span>
										{validator.agreement_1h.incomplete &&
											<span className="float-right"><Badge variant="danger">INCOMPLETE</Badge></span>
										}
									</td>
								</tr>
							</tbody>
							</>
							}
							{validator.agreement_24h && validator.agreement_24h.score &&
							<>
							<thead>
							<tr className={getAgreementStyle(validator.agreement_24h.score, 'table-')}>
								<th>Agreement (24h):</th>
								<th>
									{validator.agreement_24h.score ?
									<span className="hash">
										<FormattedNumber value={validator.agreement_24h.score * 100} maximumFractionDigits={2} />%
									</span>
									: <mark>UNAVAILABLE</mark>
									}
								</th>
							</tr>
						</thead>
						<tbody>
								<tr>
									<td>Ledgers missed (of Total)</td>
									<td>
										<span className="hash">
											{validator.agreement_24h.missed > 0 ?
												<Badge variant="warning"><FormattedNumber value={validator.agreement_24h.missed} /></Badge> :
												<FormattedNumber value={validator.agreement_24h.missed} />
											} of <FormattedNumber value={validator.agreement_24h.total} />
										</span>
										{validator.agreement_24h.incomplete &&
											<span className="float-right"><Badge variant="danger">INCOMPLETE</Badge></span>
										}
									</td>
								</tr>
							</tbody>
							</>
							}
						</Table>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	</div>
	);
}


ValidatorInfo.propTypes = {
	validator: PropTypes.object.isRequired,
	error: PropTypes.object,
}

export default ValidatorInfo;
