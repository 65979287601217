import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import XIcon from '../lib/XIcon';

const RipplingIcon = () => {
  return (
    <span>
      <OverlayTrigger
        key={'bottom'}
        placement={'bottom'}
        overlay={
          <Tooltip id={`tooltip-bottom`}>
            Rippling
          </Tooltip>
        }
      >
        <span className="rippling-icon">
          <XIcon icon='waveform-path' className="text-info" />
        </span>
      </OverlayTrigger>
    </span>
  )
}

export default RipplingIcon;