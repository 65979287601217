import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { LinkContainer } from 'react-router-bootstrap';
import { Helmet } from 'react-helmet';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import Loading from '../common/Loading';
import IntegrationGuide from '../common/IntegrationGuide';
import SponsorContainer from '../sponsorship/SponsorContainer';
import TextAd from '../sponsorship/TextAd';
import ObjectInfo from './ObjectInfo';


const LedgerObject = (props) => {
	const [ loading, setLoading ] = useState(false);
	const [ error, setErrror ] = useState(false);
	const [ ledgerObject, setLedgerObject ] = useState({});
	const index = props?.match?.params?.index

	// Fetch object when the index changes
	useEffect(() => {
		if (typeof index === 'string' && index.length > 0) {
			fetchLedgerObject(index);
		}
	}, [index]);

	const fetchLedgerObject = (index) => {
		// Unset flags
		setLoading(true);
		setErrror(false);

		// Fetch object from the API
		fetch(`${XRPSCAN_API_URL}/object/${index}`)
		.then(handleResponse)
		.then(data => {
			setLoading(false);
			setLedgerObject(data);
		})
		.catch(error => {
			setLoading(false);
			setErrror(true);
			setLedgerObject({});
		})
		.finally(() => {
			setLoading(false);
		});
	}

	if (loading) {
		return <Loading />;
	}

	return (
		<div>
		<Helmet>
			<title>Object {ledgerObject?.index ? ledgerObject.index : ""} | XAHSCAN</title>
		</Helmet>
		<Row>
			<Col>
				<IntegrationGuide/>
			</Col>
		</Row>
		<Row>
			<Col xs={12} md={4}>
				<Breadcrumb>
					<LinkContainer to={`/`}><Breadcrumb.Item>Home</Breadcrumb.Item></LinkContainer>
					{ledgerObject?.index &&
					<Breadcrumb.Item active className="text-truncate">{ledgerObject.index }</Breadcrumb.Item>
					}
				</Breadcrumb>
			</Col>
			<Col xs={12} md={8}>
				<SponsorContainer />
			</Col>
		</Row>
		<Row>
			<Col xs={12} md={12}>
				<ObjectInfo ledgerObject={ledgerObject} error={error}/>
			</Col>
		</Row>
		<Row>
			<Col xs={12} md={12}>
				<TextAd/>
			</Col>
		</Row>
	</div>
	);
}

export default LedgerObject;