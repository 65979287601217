import React from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import Loading from '../common/Loading';
import Paginator from '../lib/Paginator';
import TxItem from '../lib/TxItem';
import XIcon from '../lib/XIcon';


class AccountTransaction extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			address: this.props.address,
			activationHash: this.props.activationHash,
			transactions: [],
			marker: null,
			markers: [],
			page: 0,
			loading: false,
			error: null,
		}

		this.handlePaginationClick = this.handlePaginationClick.bind(this)
	}

	componentDidMount() {
		this.fetchTransactions()
	}

	fetchTransactions(nextMarker=null) {
		this.setState({ loading: true })
		const query_string = nextMarker ? `?marker=${nextMarker}` : ''
		const { address } = this.state

		fetch(`${XRPSCAN_API_URL}/account/${address}/transactions${query_string}`)
			.then(handleResponse)
			.then((data) => {
				const { transactions, marker } = data
				const { markers, page } = this.state
				if (marker) markers[page + 1] = marker

				this.setState({
					transactions: transactions,
					marker: marker,
					markers: markers,
					loading: false,
				})
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error.errorMessage,
				})				
			});
	}

	handlePaginationClick(direction, nextMarker) {
		const { page }  = this.state;

		if (direction === 'next') {
			this.setState({ page: page + 1 },	() => {
				this.fetchTransactions(nextMarker);
			});
		}
		else {
			this.setState({	page: page - 1 },	() => {
				this.fetchTransactions(nextMarker);
			});
		}
	}

	render() {
		const { address, activationHash, transactions, loading, page, markers } = this.state;
		const previousMarker = markers[page - 1]
		const nextMarker = markers[page + 1]

		if (loading) {
			return <Loading />
		}

		return(
			<div>
				<Table responsive>
					<thead>
						<tr>
							<th>Type</th>
							<th>Date</th>
							<th>Tx hash</th>
							<th>From</th>
							<th className="text-center"><XIcon icon="exchange" className="text-secondary"/></th>
							<th>To</th>
							<th className="text-right">Amount</th>
							<th className="text-center"><XIcon icon="check" className="text-secondary"/></th>
						</tr>
					</thead>
					<tbody>
						{ transactions.map((tx) => (
							<TxItem
								key={tx.hash}
								tx={tx}
								address={address}
								activationHash={activationHash}
							/>
						))}
					</tbody>
				</Table>
				<Paginator
					page={page}
					previousMarker={previousMarker}
					nextMarker={nextMarker}
					handlePaginationClick={this.handlePaginationClick}
				/>				
			</div>
		)
	}
}


AccountTransaction.propTypes = {
	address: PropTypes.string.isRequired,
	activationHash: PropTypes.string,
};

export default AccountTransaction;