import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './XIcon.css';

const XIcon = (props) => {
  const { faStyle, icon, size, className, color } = props;

  return (
    <FontAwesomeIcon
      icon={[faStyle, icon]}
      size={size}
      className={className}
      color={color}
    />
  );
}

XIcon.defaultProps = {
  faStyle: 'fad',
  className: 'text-info',
}

// FontAwesome styles: ['solid', 'regular', 'light', 'thin', 'duotone', 'brands']

XIcon.propTypes = {
  faStyle: PropTypes.oneOf(['fas', 'far', 'fal', 'fat', 'fad', 'fab']),
  icon: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
}

export default XIcon;