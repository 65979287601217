import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import LedgerEntryTypeTag from '../lib/LedgerEntryTypeTag';
import Money from '../lib/Money';
import EmptyStateNoRecord from '../lib/EmptyStateNoRecord';
import Loading from '../common/Loading';
import { XRPSCAN_API_URL } from '../../config';
import { LedgerEntryType, LedgerEntryTypeOwner, handleResponse } from '../common/Helpers';
import AdvancedTable from '../lib/AdvancedTable';
import XIcon from '../lib/XIcon';
import XBadge from '../lib/XBadge';
import { parseRippleStateFlags, parseSignerListFlags } from '../common/FlagHelpers';
import { RESERVE_INCREMENT } from '../common/Constants';

const AccountObject = (props) => {
	const { address } = props;
	const [ ledgerObjects, setLedgerObjects ] = useState([]);
	const [ loading, setLoading ] = useState(false);

	const columns = useMemo(
		() => [
			{
				Header: "Ledger objects",
				columns: [
				{
					Header: "#",
					accessor: "idx",
					className: "text-right",
				},
				{
					Header: "Object",
					accessor: "LedgerEntryType",
					Cell: ({ cell: { value }}) => <LedgerEntryTypeTag type={value} />,
					className: "text-left",
				},
				{
					Header: "Index",
					accessor: "index",
					Cell: ({ cell: { value }}) => <Link to={`/object/${value}`}>{value}</Link>,
                    className: "hash"
				},
				{
					Header: "Flags",
					accessor: "Flags",
					className: "text-right"
				},
				{
					Header: "Owner",
					accessor: "objectOwner",
					className: "text-center",
				},
				{
					Header: "Reserve",
					accessor: "objectOwnerReserve",
					className: "text-center",
				},
				],
			},
			],
			[],
	)

	// Is the account address responsible for putting in the owner reserve
	// for this RippleState object? Usually, the owner is the account that
	// created the object.
	const isObjectOwner = useCallback((obj) => {
		if (obj?.LedgerEntryType && LedgerEntryTypeOwner.includes(obj.LedgerEntryType)) {
			if (obj.LedgerEntryType === LedgerEntryType.RippleState) {
				const flags = parseRippleStateFlags(obj.Flags);
				if (flags?.lsfHighReserve && obj?.HighLimit?.issuer === address) {
					return true;
				} else if (flags?.lsfLowReserve && obj?.LowLimit?.issuer === address) {
					return true;
				} else {
					return false;
				}
			} else if (
				obj.LedgerEntryType === LedgerEntryType.Check ||
				obj.LedgerEntryType === LedgerEntryType.DepositPreauth ||
				obj.LedgerEntryType === LedgerEntryType.Escrow ||
				obj.LedgerEntryType === LedgerEntryType.PayChannel ||
				obj.LedgerEntryType === LedgerEntryType.Offer ||
				obj.LedgerEntryType === LedgerEntryType.Ticket
				) {
				return obj.Account === address
			} else {
				return true;
			}
		}
		return false;
	}, [address]);

	// Return owner reserve component if this object claims owner reserve
	const getOwnerReserve = useCallback((obj) => {
		const OwnerReserve = RESERVE_INCREMENT;

		if (isObjectOwner(obj)) {
			let reserveRequirement = OwnerReserve * 1;

			// Handle Pre MultiSignReserve signer lists: https://xrpl.org/signerlist.html#signerlist-flags
			if (obj.LedgerEntryType === LedgerEntryType.SignerList) {
				if (!parseSignerListFlags(obj.Flags)?.lsfOneOwnerCount) {
					const numSigners = obj?.SignerEntries ? obj.SignerEntries.length : 0
					reserveRequirement = OwnerReserve * (numSigners + 2)
				}
			}

			return <XBadge variant="success"><Money value={reserveRequirement} drops/></XBadge>
		} else {
			return <></>
		}
	}, [isObjectOwner]);

	/*
	* Add custom properties.
	*/
	const addProperties = useCallback((_objects) => {
		const populatedLedgerObjects = _objects.map((obj, idx) => {
			obj.idx = idx + 1;
			if (isObjectOwner(obj)) {
				obj.objectOwner = <XIcon icon="check" className="text-success ml-1 mr-1"/>
				obj.objectOwnerReserve = getOwnerReserve(obj)
			}
			return obj;
		});
		return populatedLedgerObjects;
	}, [getOwnerReserve, isObjectOwner]);

	useEffect(() => {
		setLoading(true);
		fetch(`${XRPSCAN_API_URL}/account/${address}/objects`)
			.then(handleResponse)
			.then((data) => {
				setLedgerObjects(addProperties(data));
			})
			.catch((error) => {
				setLedgerObjects([]);
			})
			.finally(() => {
				setLoading(false);
			});
			return () => {
				setLedgerObjects([]);
			}
	}, [address, addProperties]);

	if (loading) {
		return <Loading/>;
	}

	if (!loading && ledgerObjects && ledgerObjects.length === 0) {
		return (
			<EmptyStateNoRecord title="No Objects">
				This account owns no objects
			</EmptyStateNoRecord>
		)
	} else {
		return (
            <>
                <AdvancedTable
                    columns={columns}
                    data={ledgerObjects}
                    filterProperty='index'
                    filterPlaceholder='Search by object ID'
                />
            </>
        )
	}
}

AccountObject.propTypes = {
	address: PropTypes.string.isRequired,
}

export default AccountObject;