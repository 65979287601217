import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CardGroup from 'react-bootstrap/CardGroup';
import Card from 'react-bootstrap/Card';
import MetricCard from './MetricCard';

const TransactionSummary = (props) => {
  const {
    ledger_index,
    tx_per_second,
   } = props;

  return (
    <Row>
      <Col xs="12" md="12">
      <Card className="shadow-sm">
        <Card.Body className="metric-card">
        <CardGroup>
          <MetricCard metric={ledger_index} icon="clipboard">Ledger</MetricCard>
          <MetricCard metric={9} unit="seats" icon="users">Governance</MetricCard>
          <MetricCard metric={tx_per_second} unit="tx/s" icon="tachometer">TPS</MetricCard>
        </CardGroup>
        </Card.Body>
      </Card>
      </Col>
    </Row>
  );
}

TransactionSummary.propTypes = {
  ledger_index: PropTypes.number,
  payments_count: PropTypes.number,
  transaction_count: PropTypes.number,
  accounts_created: PropTypes.number,
  ledger_interval: PropTypes.number,
  tx_per_second: PropTypes.number,
  xrp_destroyed: PropTypes.number,
};

export default TransactionSummary;
