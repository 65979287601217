import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import XIcon from '../lib/XIcon';


const CardTitleContainer = (props) => {
  const { icon, title } = props;

  return (
    <Row>
      <Col>
        <XIcon icon={icon} />
        <span className="ml-2">{title}</span>
      </Col>
    </Row>
  );
}

CardTitleContainer.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default CardTitleContainer;