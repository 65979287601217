import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { LinkContainer } from 'react-router-bootstrap';
import { Helmet } from 'react-helmet';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import BalanceInfo from './BalanceInfo';
import BalanceTable from './BalanceTable';
import Loading from '../common/Loading';
import Analytics from '../common/Analytics';
import XIcon from '../lib/XIcon';
import IntegrationGuide from '../common/IntegrationGuide';
import SponsorContainer from '../sponsorship/SponsorContainer';
import TextAd from '../sponsorship/TextAd';

class Balance extends React.Component {
	constructor() {
		super();

		this.state = {
			facts: {},
			objectCount: {},
			loading: false,
		}
	}

	componentDidMount() {
		this.fetchObjectCount();
		this.fetchFacts();
		Analytics.pageview();
	}

  fetchFacts() {
		fetch(`${XRPSCAN_API_URL}/fact/XAH`)
			.then(handleResponse)
			.then((data) => {
				this.setState({
					facts: data,
				});
			})
			.catch(() => {
			});
	}

  fetchObjectCount() {
		fetch(`${XRPSCAN_API_URL}/fact/ObjectCount`)
			.then(handleResponse)
			.then((data) => {
				this.setState({
					objectCount: data,
				});
			})
			.catch(() => {
			});
	}

	render() {
		const {
			facts,
			objectCount,
			loading,
		} = this.state;

		// Render only loading component if loading state is set to true
		if (loading) {
			return <Loading />
		}

		return (
			<div>
				<Helmet>
					<title>XAH Rich list and balance distribution | XAHSCAN</title>
				</Helmet>
				<Row>
					<Col>
						<IntegrationGuide/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={4}>
						<Breadcrumb>
							<LinkContainer to={`/`}><Breadcrumb.Item>Home</Breadcrumb.Item></LinkContainer>
							<Breadcrumb.Item active>XAH distribution</Breadcrumb.Item>
						</Breadcrumb>
					</Col>
					<Col xs={12} md={8}>
						<SponsorContainer />
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12}>
						<BalanceInfo 
							facts={facts}
							objectCount={objectCount}
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12}>
						<TextAd/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12}>
						<Card>
							<Card.Body>
								<Card.Title>
									<XIcon icon='user' />
									<span className="pl-2">Top accounts by XAH balance</span>
								</Card.Title>
								<BalanceTable total_coins={facts.total_coins}/>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</div>
		);		
	}
}

export default Balance;