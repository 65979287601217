import React from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import XBadge from '../lib/XBadge';

const TransactionMemo = (props) => {
  const { memos } = props;

  return (
    <div>
      <Table responsive condensed="true">
        <thead>
          <tr>
            <th colSpan="2">Memos</th>
          </tr>
        </thead>
        <tbody>
          { memos.map((memo, index) =>
          <tr key={index}>
            <td>
              {memo && memo.Memo && memo.Memo.MemoType &&
              <XBadge variant="secondary" className='text-monospace'>{memo.Memo.MemoType}</XBadge>
              }
            </td>
            <td>
              {memo && memo.Memo && memo.Memo.MemoData}
            </td>
          </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

TransactionMemo.propTypes = {
  memos: PropTypes.array.isRequired,
}

export default TransactionMemo;