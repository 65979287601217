import React from 'react';
import PropTypes from 'prop-types';
import XBadge from './XBadge';
import { Link } from 'react-router-dom';
import { FormattedNumber } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const LedgerTag = (props) => {
	const { ledger_index, previous, next } = props;
	if (ledger_index) {
		return (
			<span>
				{previous && ledger_index > 32570 &&
				<span className="ledger-arrow-left">
					<Link to={`/ledger/${ledger_index - 1}`}>
						<FontAwesomeIcon icon={['fad','arrow-left']} />
					</Link>
				</span>
				}
				<span>
					<XBadge variant="danger" className="ledger-tag">
						<Link to={`/ledger/${ledger_index}`}>
							{ ledger_index === 32570 ?
								'GENESIS' :
								<FormattedNumber value={ledger_index} />
							}
						</Link>
					</XBadge>
				</span>
				{next &&
				<span className="ledger-arrow-right">
					<Link to={`/ledger/${ledger_index + 1}`}>
						<FontAwesomeIcon icon={['fad','arrow-right']} />
					</Link>
				</span>
				}
			</span>
		)
	}
	else {
		return ''
	}
}

LedgerTag.propTypes = {
	ledger_index: PropTypes.number,
	previous: PropTypes.bool,
	next: PropTypes.bool,
}

export default LedgerTag;