import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import Search from './Search';
import logo from '../../assets/images/xahscan-logo.t.png';
import './Header.css';
import { NETWORK_NAME } from '../../config'

const getNetworkName = (networkName) => {
	if (networkName === "") {
		return <></>
	} else {
		return (
		<Nav.Item>
			<Link to="/" className="nav-link text-uppercase"><small>{networkName}</small></Link>
		</Nav.Item>
		)
	}
}

const Header = () => {
	return (
		<header className="Header">
			<Container>
			  <Navbar variant="dark" className="justify-content-between">
			    <Navbar.Brand className='navbar-custom'>
				<Nav className="justify-content-start" activeKey="/">
					<Link to="/">
						<img alt="Xahscan Logo" src={logo} height="26" className="d-inline-block align-middle ml-1 mr-1" />
					</Link>
					<Nav.Item><Link to="/" className="nav-link active"><span className='font-weight-bold'>XAH SCAN</span></Link></Nav.Item>
					{getNetworkName(NETWORK_NAME)}
			    </Nav>
			    </Navbar.Brand>
			    <Nav className="justify-content-end" activeKey="/amendments">
						<Nav.Item><Link to="/validators" className="nav-link active">Validators</Link></Nav.Item>
						<Nav.Item><Link to="/amendments" className="nav-link active">Amendments</Link></Nav.Item>
						<Nav.Item><Link to="/search" className="nav-link active">Search</Link></Nav.Item>
			    </Nav>
			    <Search/>
			  </Navbar>
		  </Container>
	  </header>
	);
}

export default Header;