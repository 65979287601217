import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import LedgerTag from '../lib/LedgerTag';
import LedgerEntryTypeTag from '../lib/LedgerEntryTypeTag';
import XIcon from '../lib/XIcon';
import { getAffectedTypeLabel, getAffectedTypeStyle } from '../common/Helpers';


const TransactionGraph = (props) => {
  const { affectedNodes } = props;

  const nodes = affectedNodes.map((node) => {
    const keys = Object.keys(node);
    return ({...node[keys[0]], ...{AffectType: keys[0]}})
  })

  /* 
  * Return keys that have values that are printable (non-objects)
  */
  const getStringValueKeys = (node) => {
    let stringValueKeys = [];
    for (let key of Object.keys(node)) {
      if (['string', 'number'].includes(typeof(node[key])) && key !== 'AffectType') {
        stringValueKeys.push(key);
      }
    }
    return stringValueKeys;
  }

  /* 
  * Detect supported Ledger objects and return decorated tags for them.
  */
  const getValueTag = (key, value) => {
    if (key === 'LedgerEntryType') {
      return <LedgerEntryTypeTag type={value} />
    }
    else if (key === 'PreviousTxnLgrSeq') {
      return <LedgerTag ledger_index={value} />
    }
    else if (key === 'LedgerIndex') {
      return <span className="hash text-muted"><Link to={`/object/${value}`}>{value}</Link></span>
    }
    else if (key === 'PreviousTxnID') {
      return <span className="hash text-muted"><Link to={`/tx/${value}`}>{value}</Link></span>
    }
    else if (typeof(value) === 'string' || typeof(value) === 'number') {
      return <span>{value}</span>
    }
    else {
      return <span></span>
    }
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <Card.Title>
            <XIcon icon='project-diagram' />
            <span className="pl-2">Affected Nodes</span>
          </Card.Title>
          { nodes.map((node, index) =>
          <div key={index}>
            <Row>
              <Col xs={12} md={12}>
                <Table responsive>
                  <thead>
                    <tr className={getAffectedTypeStyle(node.AffectType, 'table-')}>
                      <th colSpan={2}>{getAffectedTypeLabel(node.AffectType)}</th>
                    </tr>
                  </thead>
                  <tbody>
                  {getStringValueKeys(node).map((key) => 
                    <tr key={key}>
                      <td className="graph-key"><span>{key}</span></td>
                      <td>{getValueTag(key, node[key])}</td>
                    </tr>
                  )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

TransactionGraph.defaultProps = {
  affectedNodes: [],
}

TransactionGraph.propTypes = {
  affectedNodes: PropTypes.array.isRequired,
}

export default TransactionGraph;
