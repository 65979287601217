import React from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { FormattedDate, FormattedNumber } from 'react-intl';
import { getAgreementStyle, getChainStyle } from '../common/Helpers';
import Loading from '../common/Loading';
import XIcon from '../lib/XIcon';
import XBadge from '../lib/XBadge';


const ValidatorReport = (props) => {
	const { reports } = props;

	if (reports.length === 0)
		return <Loading />

	return (
		<div>
			<Card>
				<Card.Body>
					<Card.Title>
						<XIcon icon='history' />
						<span className="pl-2">History</span>
					</Card.Title>
					<Table responsive>
						<thead>
							<tr>
								<th>Date</th>
								<th>Chain</th>
								<th>Agree</th>
								<th>Miss</th>
								<th>Total</th>
								<th>Score</th>
							</tr>
						</thead>
						<tbody>
							{ reports.map((report, index) =>
							<tr key={index} className={getAgreementStyle(report.score, 'table-', '')}>
								<td><FormattedDate value={report.date} /></td>
								<td><XBadge variant={getChainStyle(report.chain)} className="text-uppercase">{report.chain}</XBadge></td>
								<td><span className="hash"><FormattedNumber value={report.total - report.missed} /></span></td>
								<td>
									<span className="hash">
										<FormattedNumber value={report.missed} />
									</span>
								</td>
								<td><span className="hash"><FormattedNumber value={report.total} /></span></td>
								<td>
									<span className="hash">
										<FormattedNumber value={report.score * 100} maximumFractionDigits={2} />%
									</span>
								</td>
							</tr>
							)}
						</tbody>
					</Table>
				</Card.Body>
				<Card.Footer>{reports.length || 0} reports fetched.</Card.Footer>
			</Card>
		</div>
	);
}

ValidatorReport.propTypes = {
	reports: PropTypes.array.isRequired,
}

export default ValidatorReport;