import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LedgerEntryType } from '../../common/Helpers';
import MoneyPenny from '../../lib/MoneyPenny';
import { getFlagTag, parseOfferFlags } from '../../common/FlagHelpers';

const Offer = (props) => {
	const { node } = props;
    if ( node?.LedgerEntryType === LedgerEntryType.Offer ) {
        return (
            <>
                {node?.BookDirectory &&
                <tr>
                    <td>BookDirectory:</td>
                    <td>
                        <span>
                            <Link to={`/object/${node.BookDirectory}`}>{node.BookDirectory}</Link>
                        </span>
                    </td>
                </tr>
                }

                {node?.BookNode >= 0 &&
                <tr>
                    <td>BookNode:</td>
                    <td>
                        <span>
                           {node.BookNode}
                        </span>
                    </td>
                </tr>
                }

                {node?.TakerPays &&
                <tr>
                    <td>TakerPays:</td>
                    <td><MoneyPenny amount={node.TakerPays} showIssuer/></td>
                </tr>
                }

                {node?.TakerGets &&
                <tr>
                    <td>TakerGets:</td>
                    <td><MoneyPenny amount={node.TakerGets} showIssuer/></td>
                </tr>
                }

                {node?.Flags > 0 &&
                <tr>
                    <td>Flags:</td>
                    <td>
                        {getFlagTag(node.Flags, parseOfferFlags)}
                    </td>
                </tr>
                }
            </>
        );    
    } else {
        return <></>;
    }
}

Offer.propTypes = {
	node: PropTypes.object,
}

export default Offer;