import React from 'react';
import Card from 'react-bootstrap/Card';
import XIcon from '../lib/XIcon';
import AccountTag from '../lib/AccountTag';

import './GovernanceSummary.css';
import XBadge from '../lib/XBadge';
import { Table } from 'react-bootstrap';

const GovernanceSummary = () => {

    const members = [
        { name: "XRPL-Labs", address: "rD74dUPRFNfgnY2NzrxxYRXN4BrfGSN6Mv", seat: "L1" },
        { name: "Titanium OU", address: "rN7XCq12KBvBLKad3wWsVUwmb3dNx1fx3e", seat: "L1" },
        { name: "Evernode Labs Ltd.", address: "ra7MQw7YoMjUw6thxmSGE6jpAEY3LTHxev", seat: "L1" },
        { name: "Digital Governing OU", address: "rfMB6RCNdWSB6TJXYwCEU5HvDC2eArJp8h", seat: "L1" },
        { name: "Gatehub Limited", address: "r4FF5jjJMS2XqWDyTYStWrgARsj3FjaJ2J", seat: "L1" },
        { name: "Xahau Projects Table", address: "rwyypATD1dQxDbdQjMvrqnsHr2cQw5rjMh", seat: "L2" },
        { name: "Xahau Dev Table", address: "r4FRPZbLnyuVeGiSi1Ap6uaaPvPXYZh1XN", seat: "L2" },
        { name: "Xahau Auditors and Enterprise", address: "r6QZ6zfK37ZSec5hWiQDtbTxUaU2NWG3F", seat: "L2" },
        { name: "Xahau Exchange Table", address: "rHsh4MNWJKXN2YGtSf95aEzFYzMqwGiBve", seat: "L2" },
    ];

	return (
		<div>
			<Card>
				<Card.Body>
					<Card.Title>
						<XIcon icon='chart-bar' />
						<span className="pl-2">Governance</span>
					</Card.Title>
					<Table responsive>
						<thead>
							<tr>
								<th className="text-center"><span className="text-muted">Seat #</span></th>
								<th className="text-left"><span className="text-muted">Entity</span></th>
								<th className="text-center"><span className="text-muted">Seat type</span></th>
							</tr>
						</thead>
						<tbody>
							{ members.map((member, index) => (
							<tr key={index}>
								<td className="text-center">{index}</td>
								<td className="text-left"><AccountTag name={member} link>{member.address}</AccountTag></td>
								<td className="text-center">
                                    <XBadge variant={member.seat === "L1" ? "primary" : "info"}>
                                    <span className='ml-2 mr-2'>{member.seat}</span></XBadge>
                                </td>
							</tr>
							))}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
		</div>
	);
}

export default GovernanceSummary;
