import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Money from '../lib/Money';
import CardTitleContainer from '../lib/CardTitleContainer';
import BalanceInfoPie from './BalanceInfoPie';
import { FormattedNumber } from 'react-intl';
import XBadge from '../lib/XBadge';

const BalanceInfo = (props) => {
	const { 
		facts,
		objectCount,
	} = props;

	return (
	<div>
		<Card className="shadow-sm">
			<Card.Body>
				<Card.Title>
					<CardTitleContainer icon="chart-pie-simple" title="XAH distribution" />
				</Card.Title>
				<Row>
					<Col xs={12} md={6}>
						<Table responsive>
							<thead>
								{facts.max_supply > 0 &&
								<tr>
									<th>Max supply:</th>
									<th className="text-right">
										<Money value={facts.total_coins} drops max={0}/>
									</th>
								</tr>
								}
							</thead>
							<tbody>
								{objectCount.AccountRoot > 0 &&
								<tr>
									<td>Active accounts:</td>
									<td className="text-right">
										<div className='text-monospace'>
											<FormattedNumber value={objectCount.AccountRoot} />
										</div>
									</td>
								</tr>
								}
								{facts.total_coins > 0 &&
								<tr>
									<td>Total available:</td>
									<td className="text-right">
										<Money value={facts.total_coins} drops max={0}/>
									</td>
								</tr>
								}
								{facts.burned_coins > 0 &&
								<tr>
									<td>Burned:</td>
									<td className="text-right">
										<Money value={facts.burned_coins} drops max={0}/>
									</td>
								</tr>
								}
								{facts.escrowed_coins > 0 &&
								<tr>
									<td>Escrowed:</td>
									<td className="text-right">
										<Money value={facts.escrowed_coins} drops max={0}/>
									</td>
								</tr>
								}
								{facts.circulating_coins > 0 &&
								<tr>
									<td>Circulating supply:</td>
									<td className="text-right">
										<XBadge variant="primary">
											<Money value={facts.circulating_coins} drops max={0}/>
										</XBadge>
									</td>
								</tr>
								}
							</tbody>
						</Table>
					</Col>

					<Col xs={12} md={6}>
						<BalanceInfoPie objectCount={objectCount} />
					</Col>
				</Row>
			</Card.Body>
		</Card>
	</div>
	);
}

BalanceInfo.propTypes = {
	facts: PropTypes.object.isRequired,
	objectCount: PropTypes.object.isRequired,
}

export default BalanceInfo;