import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const TxHash = (props) => {
	const { hash } = props;
    if (hash && typeof hash === 'string') {
        return <span className="text-muted address-tag"><Link to={`/tx/${hash}`}>{hash}</Link></span>
    } else {
        return <></>
    }
}

TxHash.propTypes = {
	hash: PropTypes.string.isRequired,
}

export default TxHash;