import React from 'react';
import PropTypes from 'prop-types';
import { LedgerEntryType } from '../../common/Helpers';
import MoneyPenny from '../../lib/MoneyPenny';
import { getFlagTag, parseRippleStateFlags } from '../../common/FlagHelpers';
import XBadge from '../../lib/XBadge';

const RippleState = (props) => {
	const { node } = props;
    
    if ( node?.LedgerEntryType === LedgerEntryType.RippleState ) {
        const flags = parseRippleStateFlags(node.Flags);
        return (
            <>
                {node?.HighNode &&
                <tr>
                    <td>HighNode:</td>
                    <td>{node.HighNode}</td>
                </tr>
                }
                {node?.HighQualityIn &&
                <tr>
                    <td>HighQualityIn:</td>
                    <td>{node.HighQualityIn}</td>
                </tr>
                }
                {node?.HighQualityOut &&
                <tr>
                    <td>HighQualityOut:</td>
                    <td>{node.HighQualityOut}</td>
                </tr>
                }
                {node?.HighLimit &&
                <tr>
                    <td>HighLimit:</td>
                    <td>
                        <MoneyPenny amount={node.HighLimit} showIssuer/>
                        {flags['lsfHighReserve'] &&
                        <XBadge variant="primary" className="ml-2">OWNER RESERVE</XBadge>
                        }
                    </td>
                </tr>
                }
    
                {node?.Balance &&
                <tr>
                    <td>Balance:</td>
                    <td><MoneyPenny amount={node.Balance} showIssuer/></td>
                </tr>
                }

                {node?.LowLimit &&
                <tr>
                    <td>LowLimit:</td>
                    <td>
                        <MoneyPenny amount={node.LowLimit} showIssuer/>
                        {flags['lsfLowReserve'] &&
                        <XBadge variant="primary" className="ml-2">OWNER RESERVE</XBadge>
                        }
                    </td>
                </tr>
                }    
                {node?.LowNode &&
                <tr>
                    <td>LowNode:</td>
                    <td>{node.LowNode}</td>
                </tr>
                }
                {node?.LowQualityIn &&
                <tr>
                    <td>LowQualityIn:</td>
                    <td>{node.LowQualityIn}</td>
                </tr>
                }
                {node?.LowQualityOut &&
                <tr>
                    <td>LowQualityOut:</td>
                    <td>{node.LowQualityOut}</td>
                </tr>
                }

                {node?.Flags > 0 &&
                <tr>
                    <td>Flags:</td>
                    <td>
                        {getFlagTag(node.Flags, parseRippleStateFlags)}
                    </td>
                </tr>
                }
            </>
        );    
    } else {
        return <></>;
    }
}

RippleState.propTypes = {
	node: PropTypes.object,
}

export default RippleState;