import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { LinkContainer } from 'react-router-bootstrap';
import { Helmet } from 'react-helmet';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import ValidatorInfo from './ValidatorInfo';
import ValidatorReport from './ValidatorReport';
import ValidatorChart from './ValidatorChart';
import Loading from '../common/Loading';
import NotFound from '../notfound/NotFound';
import Analytics from '../common/Analytics';
import IntegrationGuide from '../common/IntegrationGuide';
import SponsorContainer from '../sponsorship/SponsorContainer';
import TextAd from '../sponsorship/TextAd';

class Validator extends React.Component {
	constructor() {
		super();

		this.state = {
			validator: {},
			loading: false,
			reports: [],
			error: null,
		}
	}

	componentDidMount() {
		const validation_public_key = this.props.match.params.validation_public_key;
		this.fetchValidator(validation_public_key);
		this.fetchValidatorReports(validation_public_key);
    Analytics.pageview();
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.location.pathname !== nextProps.location.pathname) {
			const new_validation_public_key = nextProps.match.params.validation_public_key;
			this.fetchValidator(new_validation_public_key);
			this.fetchValidatorReports(new_validation_public_key);
	    Analytics.pageview();
		}
	}

	fetchValidator(validation_public_key) {
		this.setState({ loading: true })
		fetch(`${XRPSCAN_API_URL}/validator/${validation_public_key}`)
			.then(handleResponse)
			.then((data) => {
				this.setState({
					validator: data,
					loading: false,
					error: null,
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error.errorMessage,
				});
			});
	}

	fetchValidatorReports(validation_public_key) {
		fetch(`${XRPSCAN_API_URL}/validator/${validation_public_key}/reports`)
			.then(handleResponse)
			.then((data) => {
				const { reports } = data;
				this.setState({
					reports: reports,
				});
			})
			.catch((error) => {
				this.setState({
				});
			});
	}


	render() {
		const {
			validator,
			loading,
			reports,
			error,
		} = this.state;

		// render only loading component if loading state is set to true
		if (loading) {
			return <Loading />
		}

		if (validator.result !== 'success') {
			return <NotFound />
		}

		return (
			<div>
				<Helmet>
					<title>{`${validator.validation_public_key || 'Not found'}`} - Xahau Ledger Validator | XAHSCAN</title>
				</Helmet>
				<Row>
					<Col>
						<IntegrationGuide/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={4}>
						<Breadcrumb>
							<LinkContainer to={`/`}><Breadcrumb.Item>Home</Breadcrumb.Item></LinkContainer>
							<LinkContainer to={`/validators`}><Breadcrumb.Item>Validators</Breadcrumb.Item></LinkContainer>
							<Breadcrumb.Item active className="address-tag address-tag-lg">{validator.domain || validator.validation_public_key}</Breadcrumb.Item>
						</Breadcrumb>
					</Col>
					<Col xs={12} md={8}>
						<SponsorContainer />
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12}>
						<ValidatorInfo validator={validator} error={error} />
					</Col>
				</Row>
				<Row>
				  <Col xs={12} md={12}>
				    <TextAd/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12}>
						<ValidatorChart validator={validator} reports={reports} />
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12}>
						<ValidatorReport reports={reports} />
					</Col>
				</Row>
			</div>
		);		
		
	}
}

export default Validator;