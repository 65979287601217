import React from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { FormattedNumber, FormattedRelative } from 'react-intl';
import Money from '../lib/Money';
import CardTitleContainer from '../lib/CardTitleContainer';


const LedgerList = (props) => {
	const { ledgers, error } = props;

	const getSkeleton = () => {
		let rows = [];
		for (let i = 0; i < 9; i++) {
			rows.push(<tr key={i}><td colSpan={5}><Skeleton/></td></tr>);
		}
		return rows;
	}

	if (error) {
		return <div className="error">{error}</div>
	}
	
	return (
		<div>
			<Card>
				<Card.Body>
				<Card.Title>
					<CardTitleContainer icon="clipboard-check" title="Validated ledgers" />
				</Card.Title>
					<Table responsive>
						<thead>
							<tr>
								<th className="text-muted text-left">Ledger</th>
								<th className="text-muted text-center">&Sigma;(Tx)</th>
								<th className="text-muted text-right">Burned</th>
								<th className="text-muted text-right">Time</th>
								<th className="text-muted text-center">Hash</th>
							</tr>
						</thead>
						<tbody>
							{ ledgers && ledgers.length === 0 &&
								getSkeleton()
							}
							{ ledgers.map((ledger) => (
							<tr key={ledger.ledger_index}>
								<td>
										<Link to={`/ledger/${ledger.ledger_index}`}><FormattedNumber value={ledger.ledger_index} /></Link>
								</td>
								<td className="text-center">{ledger.tx_count}</td>
								<td className="text-right"><code><Money value={ledger.destroyed_coins} min={6} max={6} drops /></code></td>
								<td className="text-right"><span className="text-nowrap"><FormattedRelative value={ledger.timestamp || ledger.close_time_human} updateInterval={3000} /></span></td>
								<td>
									<span className="hash text-muted">
										<Link to={`/ledger/${ledger.ledger_hash}`}>{ledger.ledger_hash}</Link>
									</span>
								</td>
							</tr>
							))}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
		</div>
	);
}

LedgerList.propTypes = {
	ledgers: PropTypes.array.isRequired,
	error: PropTypes.object,
};

export default LedgerList;
