import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Autosuggest from 'react-autosuggest';
import { withRouter } from 'react-router-dom';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import Analytics from '../common/Analytics';
import './Search.css';

class Search extends React.Component {
	constructor() {
		super();
		this.state = {
			value: '',
			suggestions: [],
		};
	}

	// If the user clicks on the results
	handleRedirect = (account) => {
		this.setState({
			value: '',
			suggestions: [],
		});
		Analytics.event({category: 'Search', action: 'Autocomplete selection', label: account});
		this.props.history.push(`/account/${account}`)
	}

	// If the user submits search form
	handleSubmit = (event) => {
		event.preventDefault();
		const { value } = this.state;

		// Handle searchQuery and construct urls.
		Analytics.event({category: 'Search', action: 'Search by keyword', label: value});
		fetch(`${XRPSCAN_API_URL}/search?q=${value}`)
			.then(handleResponse)
			.then((data) => {
				this.setState({
					value: '',
					suggestions: [],
				});
				this.props.history.push(data.redirect);
			})
			.catch((error) => {
				this.setState({
					value: '',
					suggestions: [],
				});
				this.props.history.push('/notfound');
			})
	}

	getSuggestions = (value) => {
		if (!value || value.length < 2) {
	    this.setState({
	      suggestions: [],
	    });
	    return [];
		}

		Analytics.event({category: 'Search', action: 'Autocomplete query', label: value});
		fetch(`${XRPSCAN_API_URL}/search/autocomplete?name=${value}`)
			.then(handleResponse)
			.then((result) => {
		    this.setState({
	      	suggestions: result,
	    	});
		});
	}

	getSuggestionValue = (suggestion) => {
		return suggestion.account;
	}

	renderSuggestion = (suggestion) => {
	  return (
	  	<span
				key={suggestion.account}
				onClick={() => this.handleRedirect(suggestion.account)}
	  	>
	  		{suggestion.name || `~${suggestion.username}`}{suggestion.desc && ` (${suggestion.desc})`}
	  	</span>
	  );
	}

	onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.getSuggestions(value);
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  }


	render() {
	  const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: 'Search Account, NFT, ~Name, Tx Hash, Ledger, Validator, Object',
      value,
      onChange: this.onChange,
      autoComplete: 'off',
    };

		return (
		  <Form inline onSubmit={this.handleSubmit} className="search-wrapper">
		  	<Autosuggest
	        suggestions={suggestions}
	        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
	        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
	        getSuggestionValue={this.getSuggestionValue}
	        renderSuggestion={this.renderSuggestion}
	        inputProps={inputProps}
	      />
				<Button variant="info" type="submit"><FontAwesomeIcon icon="search" /></Button>
		  </Form>		    	
		);
	}
}

export default withRouter(Search);
