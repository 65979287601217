import React from 'react';
import PropTypes from 'prop-types';
import { LedgerEntryType } from '../../common/Helpers';
import MoneyPenny from '../../lib/MoneyPenny';

const Check = (props) => {
	const { node } = props;
    if ( node?.LedgerEntryType === LedgerEntryType.Check ) {
        return (
            <>
                {node?.InvoiceID &&
                <tr>
                    <td>InvoiceID:</td>
                    <td>
                        <span>
                            {node.InvoiceID}
                        </span>
                    </td>
                </tr>
                }
                {node?.SendMax &&
                <tr>
                    <td>SendMax:</td>
                    <td><MoneyPenny amount={node.SendMax} showIssuer/></td>
                </tr>
                }
            </>
        );    
    } else {
        return <></>;
    }
}

Check.propTypes = {
	node: PropTypes.object,
}

export default Check;