import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { FormattedDate } from 'react-intl';
import { Link } from 'react-router-dom';


const NodeInfo = (props) => {
	const { node, error } = props;

	if (error) {
		return <div className="error">{error}</div>
	}
	else if (Object.keys(node).length === 0) {
		return <div className="empty">This Node has no data</div>
	}

	return (
		<Card>
			<Card.Body>
				<Card.Title>Node summary</Card.Title>
				<Row>
					<Col xs={12} md={12}>
						<Table responsive>
							<thead>
								<tr>
									<th>Public key:</th>
									<th className="hash text-muted"><Link to={`/node/${node.node_public_key}`}>{node.node_public_key}</Link></th>
								</tr>
							</thead>
							<tbody>
								{ node.hostid &&	<tr><td>Host ID</td><td>{node.hostid}</td></tr> }
								{ node.host &&	<tr><td>Host</td><td>{node.host}</td></tr> }
								{ node.port &&	<tr><td>Port</td><td>{node.port}</td></tr> }
								{ node.country &&	<tr><td>Country</td><td>{node.country}</td></tr> }
								{ node.isp &&	<tr><td>ISP</td><td>{node.isp}</td></tr> }
								{ node.location_source &&	<tr><td>Location source</td><td>{node.location_source}</td></tr> }
								{ node.last_updated &&
									<tr>
										<td>Last updated</td>
										<td>
											<FormattedDate
												value={node.last_updated}
												year='numeric'
												month='short'
												day='2-digit'
												hour='2-digit'
												minute='2-digit'
												second='2-digit'
												timeZone='UTC'
												timeZoneName='short'
											/>
										</td>
									</tr>
								}
								{ node.ledgers &&	<tr><td>Ledgers</td><td>{node.ledgers}</td></tr> }
								{ node.latency &&	<tr><td>Latency</td><td>{node.latency}</td></tr> }
								{ node.load_factor &&	<tr><td>Load factor</td><td>{node.load_factor}</td></tr> }
								{ node.peers &&	<tr><td>Peers</td><td>{node.peers}</td></tr> }
								{ node.quorum &&	<tr><td>Quorum</td><td>{node.quorum}</td></tr> }
								{ node.server_state &&	<tr><td>Server State</td><td>{node.server_state}</td></tr> }
								{ node.uptime &&	<tr><td>Uptime</td><td>{node.uptime}</td></tr> }
								{ node.version &&	<tr><td>Version</td><td>{node.version}</td></tr> }
								{ node.inbound_count &&	<tr><td>Inbound</td><td>{node.inbound_count}</td></tr> }
								{ node.outbound_count &&	<tr><td>Outbound</td><td>{node.outbound_count}</td></tr> }
							</tbody>
						</Table>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
}

NodeInfo.propTypes = {
	node: PropTypes.object.isRequired,
	error: PropTypes.object,
}

export default NodeInfo;