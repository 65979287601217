import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';

const highchartsConfig = (data = {}) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      height: 230,
    },
    title: {
      text: '',
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y}</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.name}: {point.percentage:.1f} %',
        },
      }
    },
    series: [{
      name: 'Objects',
      colorByPoint: true,
      data: data,
    }],
  }
}

const transformData = (data) => {
  const dd = Object.entries(data).map(d => {
    return {
      name: d[0],
      y: Number(d[1]) || 0,
    }  
  });
  return dd;
}

const BalanceInfoPie = (props) => {
	const { objectCount } = props;
  const data = transformData(objectCount);

  useEffect(() => {
    HC_exporting(Highcharts);
  }, [objectCount]);

	return (
  <Card className='shadow-sm'>
    <Card.Body>
      <HighchartsReact
        highcharts={Highcharts}
        options={highchartsConfig(data)}
      />
    </Card.Body>
  </Card>
	);
}

BalanceInfoPie.propTypes = {
	objectCount: PropTypes.object.isRequired,
}

export default BalanceInfoPie;