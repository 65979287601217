import React from 'react';
import NexoButton from '../sponsorship/NexoButton';


const SponsorContainer = () => {
  return (
    <span className="float-right d-flex flex-wrap">
        {/* <span className="mr-2 ml-2 mb-2"><NebeusButton /></span>
        <span className="mr-2 ml-2 mb-2"><WolfBetButton /></span> */}
        <span className="ml-2 mb-2"><NexoButton /></span>
    </span>
  );
}

export default SponsorContainer;