import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Loading from '../common/Loading';
import Highcharts from 'highcharts/highstock';
import HC_exporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';

const metricList = [
  ['Payment','Payments'],
  ['TicketCreate','Ticket created'],
  ['CheckCreate','Checks created'],
  ['AccountSet','Account set'],
  ['TrustSet','Trustline set'],
  ['NFTokenMint','NFToken mint'],
  ['NFTokenCreateOffer','NFToken offers'],
  ['NFTokenBurn','NFToken burn'],
  ['SetRegularKey','Regular key set'],
  ['SignerListSet','Multi-sig signer list set'],
  ['AccountDelete','Account deletions'],
  ['OfferCreate','Offers to exchange currency'],
  ['OfferCancel','Cancelled offers'],
  ['PaymentChannelCreate','New payment channels'],
  ['PaymentChannelClaim','Payment channel claims'],
  ['EscrowCreate','Escrowed XAH payments'],
  ['EscrowFinish','Escrowed XAH deliveries'],
  ['UNLModify','UNL Modified'],
];

const highchartsConfig = (data, keyName) => {
  return {
    // Chart settings
    chart: {
      scrollablePlotArea: { minWidth: 600 },
    },
    plotOptions: {
      series: {
        dataGrouping: {
          enabled: false
        }
      }
    },
    title: {
      text: keyName[1],
    },
    subtitle: {
      text: `(${keyName[0]})`,
    },
    rangeSelector: {
        selected: 4,
    },
    yAxis: {
      labels: {
        formatter: function() {
          if ( this.value >= 1000 * 1000 )
            return Highcharts.numberFormat( this.value/1000000, 1) + " M";
          if ( this.value >= 1000 )
            return Highcharts.numberFormat( this.value/1000, 1) + " K";
          else
            return Highcharts.numberFormat(this.value, 0);
        },
      },
    },

    // Responsive settings
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          chart: {
            height: 300
          },
          subtitle: {
            text: null
          },
          navigator: {
            enabled: false
          }
        }
      }]
    },

    // Chart data
    series: [
    {
      name: keyName[0],
      type: 'areaspline',
      color: Highcharts.getOptions().colors[2],
      data: data,

      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1
        },
        stops: [
          [0, Highcharts.getOptions().colors[2]],
          [1, Highcharts.Color(Highcharts.getOptions().colors[2]).setOpacity(0).get('rgba')]
        ]
        }    
    }],
  }
};

class MetricType extends React.Component {
  constructor(props) {
    super(props);
    HC_exporting(Highcharts);
  }

  componentDidMount() {
  }

  getChartData(metric, name) {
    return metric.map((datapoint) => [new Date(datapoint.date).getTime(), datapoint.type[name]]);
  }

  render() {
    const { metric } = this.props;

    if (metric && metric.length === 0) {
      return <Loading />
    }

    return (
      <div>
        <br/>
        {metricList.map((k,v) => {
          return (
            <Card className="shadow-sm" key={k}>
              <Card.Body>
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={'stockChart'}
                  options={highchartsConfig(this.getChartData(metric, k[0]), k)}
                />
              </Card.Body>
            </Card>
          );
        })}
      </div>
    );
  }
}

MetricType.propTypes = {
  metric: PropTypes.array.isRequired,
}

export default MetricType;