import React from 'react';
import PropTypes from 'prop-types';
import { LedgerEntryType } from '../../common/Helpers';
import Money from '../../lib/Money';

const Escrow = (props) => {
	const { node } = props;
    if ( node?.LedgerEntryType === LedgerEntryType.Escrow ) {
        return (
            <>
                {node?.Amount >= 0 &&
                <tr>
                    <td>Amount:</td>
                    <td>
                        <span>
                            <Money value={node.Amount} drops />
                        </span>
                    </td>
                </tr>
                }

                {node?.Condition &&
                <tr>
                    <td>Condition:</td>
                    <td>
                        <span>
                            {node.Condition}
                        </span>
                    </td>
                </tr>
                }

                {node?.CancelAfter >= 0 &&
                <tr>
                    <td>CancelAfter:</td>
                    <td>
                        <span>
                           {node.CancelAfter}
                        </span>
                    </td>
                </tr>
                }
                {node?.FinishAfter >= 0 &&
                <tr>
                    <td>FinishAfter:</td>
                    <td>
                        <span>
                           {node.FinishAfter}
                        </span>
                    </td>
                </tr>
                }
            </>
        );    
    } else {
        return <></>;
    }
}

Escrow.propTypes = {
	node: PropTypes.object,
}

export default Escrow;