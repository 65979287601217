import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import './Loading.css';

const Loading = (props) => {
	const { variant } = props;

  return (
    <Row>
      <Col xs={12} md={12}>
        <div className="Loading text-center">
          <Spinner animation="border" variant={variant}>
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      </Col>
    </Row>
    );
}

Loading.defaultProps = {
	variant: 'info',
}

Loading.propTypes = {
	variant: PropTypes.string,
}

export default Loading;