import React from 'react';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import PropTypes from 'prop-types';
import { LedgerEntryType } from '../../common/Helpers';

const DirectoryNode = (props) => {
	const { node } = props;
    if ( node?.LedgerEntryType === LedgerEntryType.DirectoryNode ) {
        return (
            <>
                {node?.ExchangeRate &&
                <tr>
                    <td>ExchangeRate:</td>
                    <td>
                        <span>
                            {node.ExchangeRate}
                        </span>
                    </td>
                </tr>
                }

                {node?.IndexPrevious &&
                <tr>
                    <td>IndexPrevious:</td>
                    <td>
                        <span>
                            {node.IndexPrevious}
                        </span>
                    </td>
                </tr>
                }

                {node?.IndexNext &&
                <tr>
                    <td>IndexNext:</td>
                    <td>
                        <span>
                            {node.IndexNext}
                        </span>
                    </td>
                </tr>
                }

                {node?.RootIndex &&
                <tr>
                    <td>RootIndex:</td>
                    <td>
                        <span>
                            <Link to={`/object/${node.RootIndex}`}>{node.RootIndex}</Link>
                        </span>
                    </td>
                </tr>
                }

                {node?.TakerGetsCurrency &&
                <tr>
                    <td>TakerGetsCurrency:</td>
                    <td>
                        <span>
                            {node.TakerGetsCurrency}
                        </span>
                    </td>
                </tr>
                }
                {node?.TakerGetsIssuer &&
                <tr>
                    <td>TakerGetsIssuer:</td>
                    <td>
                        <span>
                            {node.TakerGetsIssuer}
                        </span>
                    </td>
                </tr>
                }
                {node?.TakerPaysCurrency &&
                <tr>
                    <td>TakerPaysCurrency:</td>
                    <td>
                        <span>
                            {node.TakerPaysCurrency}
                        </span>
                    </td>
                </tr>
                }
                {node?.TakerPaysIssuer &&
                <tr>
                    <td>TakerPaysIssuer:</td>
                    <td>
                        <span>
                            {node.TakerPaysIssuer}
                        </span>
                    </td>
                </tr>
                }

                {node?.Indexes &&
                <tr>
                    <td>Indexes:</td>
                    <td>
                        <Table responsibe>
                           <thead>
                               <tr>
                                    <th>#</th>
                                    <th>Index</th>
                                </tr>
                            </thead>
                            <tbody>
                                {node.Indexes.map((Index, index) => {
                                    return(
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>
                                                <span className='hash'>
                                                    <Link to={`/object/${Index}`}>{Index}</Link>
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                    })
                                }
                            </tbody>
                        </Table>
                    </td>
                </tr>
                }                
            </>
        );    
    } else {
        return <></>;
    }
}

DirectoryNode.propTypes = {
	node: PropTypes.object,
}

export default DirectoryNode;