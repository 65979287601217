import React from 'react';
import Card from 'react-bootstrap/Card';
import Callout from '../lib/Callout';
import { NETWORK_ID, NETWORK_NAME } from '../../config';
import { XrplNetwork } from './Constants';

const TestnetNotice = () => {
    if (NETWORK_ID === XrplNetwork.XahauMainnet) {
        return <></>
    } else if (typeof NETWORK_NAME === 'string' && NETWORK_NAME.length > 0) {
        return (
        <Card border="warning" className="shadow-lg">
            <Card.Body>
                <div>
                    <Callout title={NETWORK_NAME.toUpperCase()} variant="warning"> Warning: You are exploring the {NETWORK_NAME}. Assets and tokens on this network may not have real world value. These funds are intended for testing only.</Callout>
                </div>
            </Card.Body>
        </Card>
        );        
    }
    else {
        return <></>
    }
}

export default TestnetNotice;
