/**
* Constants used in XRP Ledger.
*/
export const XAH_DROPS = 1_000_000; // Total Drops in 1 XAH
export const TOTAL_COIN_SUPPLY = 600_000_000;
export const RESERVE_BASE = 1 * XAH_DROPS; // Unit: Drops
export const RESERVE_INCREMENT = 0.2 * XAH_DROPS; // Unit: Drops

/**
* Enum of all Transaction Types supported by Xahau Ledger
*
*/

const TransactionType = {
  AccountDelete: 'AccountDelete',
  AccountSet: 'AccountSet',
  CheckCancel: 'CheckCancel',
  CheckCash: 'CheckCash',
  CheckCreate: 'CheckCreate',
  ClaimReward: 'ClaimReward',
  DepositPreauth: 'DepositPreauth',
  EscrowCancel: 'EscrowCancel',
  EscrowCreate: 'EscrowCreate',
  EscrowFinish: 'EscrowFinish',
  GenesisMint: 'GenesisMint',
  Import: 'Import',
  Invoke: 'Invoke',
  NFTokenAcceptOffer: 'NFTokenAcceptOffer',
  NFTokenBurn: 'NFTokenBurn',
  NFTokenCancelOffer: 'NFTokenCancelOffer',
  NFTokenCreateOffer: 'NFTokenCreateOffer',
  NFTokenMint: 'NFTokenMint',
  OfferCancel: 'OfferCancel',
  OfferCreate: 'OfferCreate',
  Payment: 'Payment',
  PaymentChannelClaim: 'PaymentChannelClaim',
  PaymentChannelCreate: 'PaymentChannelCreate',
  PaymentChannelFund: 'PaymentChannelFund',
  SetHook: 'SetHook',
  SetRegularKey: 'SetRegularKey',
  SignerListSet: 'SignerListSet',
  TicketCreate: 'TicketCreate',
  TrustSet: 'TrustSet',
  URITokenBurn: 'URITokenBurn',
  URITokenBuy: 'URITokenBuy',
  URITokenCancelSellOffer: 'URITokenCancelSellOffer',
  URITokenCreateSellOffer: 'URITokenCreateSellOffer',
  URITokenMint: 'URITokenMint',
  EnableAmendment: 'EnableAmendment',
  SetFee: 'SetFee',
};
Object.freeze(TransactionType);
export { TransactionType };

export const NFTokenMintFlags = {
  tfBurnable: 'tfBurnable',
  tfOnlyXRP: 'tfOnlyXRP',
  tfTrustLine: 'tfTrustLine',
  tfTransferable: 'tfTransferable',
}

export const XrplNetwork = {
  XahauTestnet: 21338,
  XahauMainnet: 21337,
}
Object.freeze(XrplNetwork)

export const Burn2MintTransactionTypes = [
  TransactionType.AccountSet,
  TransactionType.SetRegularKey,
  TransactionType.SignerListSet,
]
Object.freeze(Burn2MintTransactionTypes)
