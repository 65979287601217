import React from 'react';
import PropTypes from 'prop-types';
import { rippleTime2UnixTime } from '../common/Helpers';

const AmendmentETA = (props) => {
  const { majority } = props;

  const Z = (d) => {
    return String(d).padStart(2, "0")
  }

  const currentTime = Date.now()
  const activationTime = (rippleTime2UnixTime(majority) + (5 * 24 * 3600)) * 1000
  const ETA = (currentTime > activationTime) ? 0 : activationTime - currentTime

  const days = new Date(ETA).getUTCDate() - 1
  const hours = new Date(ETA).getUTCHours()
  const minutes = new Date(ETA).getUTCMinutes()
  // const seconds = new Date(ETA).getUTCSeconds()

  if (ETA > 0) {
    return ( <>{Z(days)}d:{Z(hours)}h:{Z(minutes)}m</> )
  } else {
    return ( <>NEXT FLAG LEDGER</>)
  }
}

AmendmentETA.propTypes = {
  majority: PropTypes.number.isRequired,
}

export default AmendmentETA;