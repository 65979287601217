import React from 'react';
import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import XIcon from './XIcon';
import Money from './Money';

const PartialPaymentTag = (props) => {
  const { amount } = props;

  return (
    <span>
      <OverlayTrigger
        key={'right'}
        placement={'bottom'}
        overlay={
          <Popover id="partial-payment">
            <Popover.Title>Partial payment</Popover.Title>
            {amount && amount.value &&
            <Popover.Content>
              Requested:&nbsp;<Money
                value={amount.value}
                currency={amount.currency}
                issuer={amount.issuer}
                drops
                min={0}
                max={6}
              />
            </Popover.Content>
            }
          </Popover>
        }
      >
        <span className="partial-payment-tag text-info">
          <XIcon icon="chart-pie-alt" size="lg"/>
        </span>
      </OverlayTrigger>
    </span>
  )
}

PartialPaymentTag.propTypes = {
  amount: PropTypes.object,
}

export default PartialPaymentTag;