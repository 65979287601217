import React from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import AccountTag from '../lib/AccountTag';


const AccountSetting = (props) => {
	const { settings } = props;
	const signers = settings.signers;

	return (
		<div>
			<Table responsive>
				<thead>
					<tr>
						<th>Setting</th>
						<th>Value</th>
					</tr>
				</thead>
				<tbody>
					{Object.entries(settings)
						.filter(([key, value]) => {
							return key !== 'signers'
						})
						.map(([key, value]) => {
						let setting;
						if (value === true) {	setting = <Badge variant="success">YES</Badge> }
						else if (value === false) {	setting = <Badge variant="danger">&nbsp;NO&nbsp;</Badge> }
						else { setting = value}

						return (
							<tr>
								<td>{key}</td>
								<td>{setting}</td>
							</tr>
						)}
					)}
				</tbody>
			</Table>
			{signers && signers.weights && Object.keys(signers).length > 0 &&
			<Table responsive>
				<thead>
					<tr>
						<td>#</td>
						<th>Signers (threshold = {signers.threshold || 0})</th>
						<th>Weight</th>
					</tr>
				</thead>
				<tbody>
					{signers.weights.map((signer, index) => {
						return (
							<tr>
								<td>{index + 1}</td>
								<td><AccountTag name={signer.addressName} link>{signer.address}</AccountTag></td>
								<td>{signer.weight}</td>
							</tr>
						)}
					)}
				</tbody>
			</Table>
			}
		</div>
	);
}

AccountSetting.propTypes = {
	settings: PropTypes.object.isRequired,
}

export default AccountSetting;