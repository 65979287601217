import React from 'react';
import PropTypes from 'prop-types';
import './XBadge.css';

const XBadge = (props) => {
  const { variant, className } = props;
  let xBadgeClassName = "xbadge";

  if (variant) {
    xBadgeClassName = `${xBadgeClassName} alert-${variant}`;
  }

  if (className) {
    xBadgeClassName = `${xBadgeClassName} ${className}`;
  }

  return (
    <span className={xBadgeClassName}>{props.children}</span>
  );
}

XBadge.defaultProps = {
  variant: null,
}

XBadge.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
}

export default XBadge;