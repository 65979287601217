import React from 'react';
import PropTypes from 'prop-types';
import { LedgerEntryType } from '../../common/Helpers';
import AccountTag from '../../lib/AccountTag';

const DepositPreauth = (props) => {
	const { node } = props;
    if ( node?.LedgerEntryType === LedgerEntryType.DepositPreauth ) {
        return (
            <>                
                {node?.Authorize &&
                <tr>
                    <td>Authorize:</td>
                    <td>
                        <AccountTag link>{node.Authorize}</AccountTag>
                    </td>
                </tr>
                }
            </>
        );    
    } else {
        return <></>;
    }
}

DepositPreauth.propTypes = {
	node: PropTypes.object,
}

export default DepositPreauth;