import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import CardTitleContainer from '../lib/CardTitleContainer';
import { TransactionType } from '../common/Constants';
import Callout from '../lib/Callout';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse, isNumeric } from '../common/Helpers';
import { useLocation, withRouter } from "react-router-dom";

const SearchForm = (props) => {
  const { handler, loading } = props;
  const location = useLocation();
  let searchParams = new URLSearchParams("?");

  if (location?.search?.length > 0) {
    searchParams = new URLSearchParams(location.search);
  }

  const spAccount = searchParams.get('Account');
  const spSourceTag = searchParams.get('SourceTag');
  const spDestination = searchParams.get('Destination');
  const spDestinationTag = searchParams.get('DestinationTag');
  const spTransactionType = searchParams.get('TransactionType');

  const getDefaultAccountOptions = (address) => {
    return address ? [{ "label": address, "value": address }] : [];
  }

  const getDefaultAccount = (address) => {
    return address ? { "label": address, "value": address } : undefined;
  }

  const getDefaultTag = (dt) => {
    return isNumeric(dt) ? dt : undefined;
  }

  useEffect(() => {
    handler({
      Account: spAccount,
      SourceTag: spSourceTag,
      Destination: spDestination,
      DestinationTag: spDestinationTag,
      TransactionType: spTransactionType,
    })
  }, [spAccount, spSourceTag, spDestination, spDestinationTag, spTransactionType]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    handler({
      Account: form.Account.value,
      SourceTag: form.SourceTag.value,
      Destination: form.Destination.value,
      DestinationTag: form.DestinationTag.value,
      TransactionType: form.TransactionType.value,
    })
  }

  const formatNameResult = (match) => {
    let label = match.account
    let value = match.account
    if (typeof(match.username) === 'string' && match.username.length > 0 ) {
      label = `~${match.username} - ${match.account}`
    } else if (typeof(match.name) === 'string' && match.name.length > 0) {
      label = match.name;
      if (typeof(match.desc) === 'string' && match.desc.length > 0) {
        label = `${match.name} (${match.desc})`;
      }
      if (match.name !== match.account) {
        label = `${label} - ${match.account}`
      }
    }
    return {label, value}
  }

  const loadOptions = (inputValue, callback) => {
    fetch(`${XRPSCAN_API_URL}/search/autocomplete?name=${inputValue}`)
    .then(handleResponse)
    .then(results => {
      if (results && results.length > 0) {
        const matches = results.map(r => formatNameResult(r))
        callback(matches)
      } else {
        callback([])
      }
    })
  }

  return(
		<Card className="shadow-sm">
    <Card.Body>
      <Row>
        <Col xs={12} md={12}>
          <Callout title="BETA" variant="info">
            <span className="ml-2">Advanced transaction search is available for &beta;eta testing. During &beta;eta test phase, transactions processed during the last 90 days can be searched.</span>
          </Callout>
        </Col>
      </Row>
      <Card.Title>
        <CardTitleContainer icon="search" title="Transaction search" />
      </Card.Title>

      <Container>
      <Form onSubmit={handleSubmit}>
        <Form.Row>
          <Form.Group as={Col} xs={12} md={8} controlId="formGridSource">
            <Form.Label>Sending account</Form.Label>
            {/* <Form.Control type="text" name="Account" placeholder="" /> */}
            <AsyncSelect
              cacheOptions
              loadOptions={loadOptions}
              defaultOptions={getDefaultAccountOptions(spAccount)}
              defaultValue={getDefaultAccount(spAccount)}
              isClearable={true}
              name={'Account'}
              placeholder={'Sending account'}
            />
          </Form.Group>

          <Form.Group as={Col} xs={12} md={4} controlId="formGridSourceTag">
            <Form.Label>Source tag</Form.Label>
            <InputGroup className="mb-2 mr-sm-2">
              <InputGroup.Prepend>
                <InputGroup.Text>ST</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control type="number" name="SourceTag" placeholder="Source tag"  value={getDefaultTag(spSourceTag)}/>
            </InputGroup>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} xs={12} md={8} controlId="formGridDestination">
            <Form.Label>Destination account</Form.Label>
            {/* <Form.Control type="text" name="Destination" placeholder="" /> */}
            <AsyncSelect
              cacheOptions
              loadOptions={loadOptions}
              defaultOptions={getDefaultAccountOptions(spDestination)}
              defaultValue={getDefaultAccount(spDestination)}
              isClearable={true}
              name={'Destination'}
              placeholder={'Destination account'}
            />
          </Form.Group>

          <Form.Group as={Col} xs={12} md={4} controlId="formGridDestinationTag">
            <Form.Label>Destination tag</Form.Label>
            <InputGroup className="mb-2 mr-sm-2">
              <InputGroup.Prepend>
                <InputGroup.Text>DT</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control type="number" name="DestinationTag" placeholder="Destination tag" defaultValue={getDefaultTag(spDestinationTag)}/>
            </InputGroup>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={12} lg={8} controlId="formGridTransactionType">
            <Form.Label>Transaction type</Form.Label>
            <Form.Control as="select" name="TransactionType" defaultValue={spTransactionType || ""}>
              <option value="">All transaction types</option>
              <option value={TransactionType.Payment}>{TransactionType.Payment}</option>
              {Object.values(TransactionType).map((txType, index ) => (
              <option key={index} value={txType}>{txType}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form.Row>
        <Button variant="info" type="submit" disabled={loading}>
          Search
        </Button>
      </Form>
      </Container>
    </Card.Body>
    </Card>
  )
}

SearchForm.defaultProps = {
  handler: () => ({}),
  loading: false,
}

SearchForm.propTypes = {
	address: PropTypes.string,
  handler: PropTypes.func,
  loading: PropTypes.bool,
}

export default withRouter(SearchForm);
