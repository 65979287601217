import React from 'react';
import PropTypes from 'prop-types';
import { LedgerEntryType } from '../../common/Helpers';
import Money from '../../lib/Money';

const PayChannel = (props) => {
	const { node } = props;
    if ( node?.LedgerEntryType === LedgerEntryType.PayChannel ) {
        return (
            <>
                {node?.Amount >= 0 &&
                <tr>
                    <td>Amount:</td>
                    <td>
                        <span>
                            <Money value={node.Amount} drops />
                        </span>
                    </td>
                </tr>
                }

                {node?.Balance >= 0 &&
                <tr>
                    <td>Balance:</td>
                    <td>
                        <span>
                            <Money value={node.Balance} drops />
                        </span>
                    </td>
                </tr>
                }

                {node?.PublicKey &&
                <tr>
                    <td>PublicKey:</td>
                    <td>
                        <span>
                            {node.PublicKey}
                        </span>
                    </td>
                </tr>
                }

                {node?.SettleDelay >= 0 &&
                <tr>
                    <td>SettleDelay:</td>
                    <td>
                        <span>
                            {node.SettleDelay}
                        </span>
                    </td>
                </tr>
                }
            </>
        );    
    } else {
        return <></>;
    }
}

PayChannel.propTypes = {
	node: PropTypes.object,
}

export default PayChannel;