import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { LinkContainer } from 'react-router-bootstrap';
import { Helmet } from 'react-helmet';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import Loading from '../common/Loading';
import NFTokenInfo from './NFTokenInfo';
import IntegrationGuide from '../common/IntegrationGuide';
import SponsorContainer from '../sponsorship/SponsorContainer';
import TextAd from '../sponsorship/TextAd';
import Analytics from '../common/Analytics';

const NFToken = (props) => {
  const [ loading, setLoading ] = useState(false);
  const [ nftoken, setNFToken ] = useState({});
	const [ error, setError ] = useState({});
  const nftoken_id = props?.match?.params?.nftoken_id

  useEffect(() => {
    fetchNFToken(nftoken_id);
    Analytics.pageview();
  }, [nftoken_id]);

  const fetchNFToken = (nftoken_id) => {
		setLoading(true);
    fetch(`${XRPSCAN_API_URL}/nft/${nftoken_id}`)
    .then(handleResponse)
    .then((nftoken) => {
      setLoading(false);
			setError({});
      setNFToken(nftoken);
    })
		.catch((error) => {
			setLoading(false);
			setNFToken({});
			setError(error);
		})
	}

	if (loading) {
		return <Loading />
	}

	return (
		<div>
		<Helmet>
			<title>NFT {`${nftoken_id}`} | XAHSCAN</title>
		</Helmet>
		<Row>
			<Col>
				<IntegrationGuide/>
			</Col>
		</Row>
		<Row>
			<Col xs={12} md={4}>
				<Breadcrumb>
					<LinkContainer to={`/`}><Breadcrumb.Item>Home</Breadcrumb.Item></LinkContainer>
					{nftoken_id &&
					<Breadcrumb.Item active className="text-truncate nft-truncate">{nftoken_id}</Breadcrumb.Item>
					}
				</Breadcrumb>
			</Col>
			<Col xs={12} md={8}>
				<SponsorContainer />
			</Col>
		</Row>
		<Row>
			<Col xs={12} md={12}>
				<NFTokenInfo nftoken={nftoken} error={error}/>
			</Col>
		</Row>
		<Row>
			<Col xs={12} md={12}>
				<TextAd/>
			</Col>
		</Row>
	</div>		
	)
}

export default NFToken;