import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Skeleton from 'react-loading-skeleton';
import LedgerTag from '../lib/LedgerTag';
import { getXRPLState } from '../common/Helpers';
import { XRPLFlagInterval } from '../common/Helpers';
import CardTitleContainer from '../lib/CardTitleContainer';


const AmendmentProgress = (props) => {
  const { vm, highestLedger } = props;
  const counter = (highestLedger % XRPLFlagInterval === 0) ? 0 : (XRPLFlagInterval - highestLedger % XRPLFlagInterval);

  return (
    <Card className="shadow-sm">
      <Card.Body>
        <Card.Title>
          <CardTitleContainer icon="vote-yea" title="Amendment voting" />
        </Card.Title>
        <Row>
          <Col>
            <Table responsive className="mb-0">
            {vm
              ?
              <tbody>
                <tr>
                  <td className="border-top-0"><span>{getXRPLState(counter)}</span></td>
                  <td className="border-top-0"><LedgerTag ledger_index={vm.ledger_index} /></td>
                  <td className="border-top-0"><span className="hash text-muted d-none d-md-block">{vm.master_key}</span></td>
                  <td className="border-top-0"><kbd>{counter}</kbd></td>
                </tr>
                <tr>
                  <td colSpan="4" className="border-top-0">
                    <ProgressBar animated variant='info' min={0} max={XRPLFlagInterval - 1} now={counter} />
                  </td>
                </tr>
              </tbody>
              :
              <tbody>
                <tr>
                  <td className="border-top-0"><Skeleton /></td>
                </tr>
                <tr>
                  <td className="border-top-0"><Skeleton /></td>
                </tr>
              </tbody>
            }
            </Table>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}


AmendmentProgress.propTypes = {
  vm: PropTypes.object.isRequired,
  highestLedger: PropTypes.number,
}

export default AmendmentProgress;
