import React from 'react';
import PropTypes from 'prop-types';
import { LedgerEntryType } from '../../common/Helpers';

const Ticket = (props) => {
	const { node } = props;
    if ( node?.LedgerEntryType === LedgerEntryType.Ticket ) {
        return (
            <>
                {node?.TicketSequence >= 0 &&
                <tr>
                    <td>TicketSequence:</td>
                    <td>
                        <span>
                            {node.TicketSequence}
                        </span>
                    </td>
                </tr>
                }
            </>
        );    
    } else {
        return <></>;
    }
}

Ticket.propTypes = {
	node: PropTypes.object,
}

export default Ticket;