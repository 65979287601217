import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import XIcon from '../lib/XIcon';


const VerifiedBadge = () => {
  return (
    <span>
      <OverlayTrigger
        key={'right'}
        placement={'right'}
        overlay={
          <Tooltip id={`tooltip-right`}>
            Verified account
          </Tooltip>
        }
      >
        <span className="badge-verified">
          <XIcon icon='badge-check' faStyle='fas' className="text-primary" size="lg"/>
        </span>
      </OverlayTrigger>
    </span>
  )
}

export default VerifiedBadge;