import React from 'react';
import PropTypes from 'prop-types';
import XBadge from './XBadge';
import {
    getLedgerEntryTypeStyle,
    getLedgerEntryTypeLabel
} from '../common/Helpers';

const LedgerEntryTypeTag = (props) => {
    const { type } = props;
	return (
        <XBadge variant={ getLedgerEntryTypeStyle( type ) }>
            { getLedgerEntryTypeLabel( type ) }
        </XBadge>
    )
}

LedgerEntryTypeTag.propTypes = {
    type: PropTypes.string.isRequired,
}

export default LedgerEntryTypeTag;
