import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import CardTitleContainer from '../lib/CardTitleContainer';
import XBadge from '../lib/XBadge';
import XIcon from '../lib/XIcon';
import Money from '../lib/Money';
import NetworkChart from './NetworkChart';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';

const getMainnetValidators = (validators) => {
  if (validators && validators.length > 0) {
    return validators.filter(v => v.chain === 'main');
  }
  else {
    return [];
  }
}

const getdUNLValidators = (validators) => {
  if (validators && validators.length > 0) {
    return validators.filter((v) => {
      return (v.chain === 'main' && v.unl && v.unl.includes('vl.xahau.org'));
    });
  }
  else {
    return [];
  }
}

const NetworkStatus = (props) => {
  const {
    validators,
    nodes,
    negativeUNLCount,
    reserveBase,
    reserveIncrement,
    serverVersions,
  } = props;

  const dUNLValidatorsCount = getdUNLValidators(validators).length
  const [ dUNLValidatorsOnline, setdUNLValidatorsOnline ] = useState(getdUNLValidators(validators).length);
  const [ dUNLQuorum, setdUNLQuorum ] = useState(0);
  const [ openLedgerFee, setOpenLedgerFee ] = useState(12);

  useEffect(() => {
    fetch(`${XRPSCAN_API_URL}/network/server_info`)
    .then(handleResponse)
    .then((server_info) => {
      if (server_info && server_info.info) {
          if (server_info.info.last_close && server_info.info.last_close.proposers) {
              setdUNLValidatorsOnline(server_info.info.last_close.proposers);
            }
          if (server_info.info.validation_quorum) {
            setdUNLQuorum(server_info.info.validation_quorum);
          }
        }
    })
    .catch((error) => {
      setdUNLValidatorsOnline("NA");
      setdUNLQuorum("NA");
    })
  })

  useEffect(() => {
    fetch(`${XRPSCAN_API_URL}/network/fee`)
    .then(handleResponse)
    .then((fee) => {
      if (
        fee &&
        fee.drops &&
        fee.drops.open_ledger_fee &&
        fee.drops.open_ledger_fee > 12
      ) {
        setOpenLedgerFee(fee.drops.open_ledger_fee);
      }
    })
  })

  return (
		<Card>
			<Card.Body>
				<Card.Title>
					<CardTitleContainer icon="network-wired" title="Network state" />
				</Card.Title>
				<Row>
					<Col xs={12} md={12}>
						<Table responsive>
							<thead>
								<tr className="text-center">
									<th>Validators</th>
									<th>Default UNL</th>
									<th>Quorum</th>
									<th>NegativeUNL</th>
									<th>Base reserve</th>
									<th>Owner reserve</th>
									<th>Open ledger Fee</th>
								</tr>
							</thead>
							<tbody>
								<tr className="text-center">
									<td className="text-center">
                  <XBadge variant="info" className="hash">
                      <XIcon icon='server' className="mr-2"/>
                      {getMainnetValidators(validators).length} MAINNET
                    </XBadge>
                  </td>
									<td>
                    <XBadge variant={dUNLValidatorsOnline < dUNLValidatorsCount ? 'warning' : 'success'} className="hash">
                      <XIcon icon='project-diagram' className="mr-2"/>
                      {dUNLValidatorsOnline}/{dUNLValidatorsCount} ONLINE
                    </XBadge>
                  </td>
									<td>
                    <XBadge variant="primary" className="hash">
                      <XIcon icon='chart-network' className="mr-2"/>
                      {dUNLQuorum}/{dUNLValidatorsOnline} VOTES
                    </XBadge>
                  </td>
                  <td>
                    <XBadge variant={negativeUNLCount === 0 ? 'success' : 'danger'} className="hash">
                      <XIcon icon='minus-circle' className="mr-2"/>
                      {negativeUNLCount}
                    </XBadge>
                  </td>
									<td>
                    <XBadge variant="secondary" className="hash">
                      <XIcon icon='equals' className="mr-2"/>
                      <Money value={reserveBase} drops/>
                    </XBadge>
                  </td>
									<td>
                  <XBadge variant="secondary" className="hash">
                      <XIcon icon='equals' className="mr-2"/>
                      <Money value={reserveIncrement} drops/>
                    </XBadge>
                  </td>
									<td>
                    <XBadge variant="info" className="hash">
                      <XIcon icon='chevron-double-up' className="mr-2"/>
                      <Money value={openLedgerFee} drops min={6} max={6}/>
                    </XBadge>
                  </td>
                </tr>
            </tbody>
						</Table>
					</Col>
				</Row>

        <Row>
          <Col xs={12} md={12}>
            <NetworkChart
              validators={validators}
              nodes={nodes}
              serverVersions={serverVersions}
            />
          </Col>
        </Row>
			</Card.Body>
		</Card>
  );
}

NetworkStatus.propTypes = {
  validators: PropTypes.array,
  nodes: PropTypes.array,
  negativeUNLCount: PropTypes.number,
  reserveBase: PropTypes.number,
  reserveIncrement: PropTypes.number,
  serverVersions: PropTypes.array,
};

export default NetworkStatus;