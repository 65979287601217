import React from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import { LedgerEntryType } from '../../common/Helpers';
import AccountTag from '../../lib/AccountTag';
import { getFlagTag, parseSignerListFlags } from '../../common/FlagHelpers';

const SignerList = (props) => {
	const { node } = props;
    if ( node?.LedgerEntryType === LedgerEntryType.SignerList ) {
        return (
            <>
                {node?.SignerListID >= 0 &&
                <tr>
                    <td>SignerListID:</td>
                    <td>
                        <span>
                            {node.SignerListID}
                        </span>
                    </td>
                </tr>
                }

                {node?.SignerQuorum >= 0 &&
                <tr>
                    <td>SignerQuorum:</td>
                    <td>
                        <span>
                            {node.SignerQuorum}
                        </span>
                    </td>
                </tr>
                }
                {node?.Flags > 0 &&
                <tr>
                    <td>Flags:</td>
                    <td>
                        {getFlagTag(node.Flags, parseSignerListFlags)}
                    </td>
                </tr>
                }


                {node?.SignerEntries &&
                <tr>
                    <td>SignerEntries:</td>
                    <td>
                        <Table responsibe>
                           <thead>
                               <tr>
                                    <th>#</th>
                                    <th>Signer</th>
                                    <th>Weight</th>
                                </tr>
                            </thead>
                            <tbody>
                                {node.SignerEntries.map((signer, index) => {
                                    return(
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td><AccountTag link>{signer?.SignerEntry?.Account}</AccountTag></td>
                                            <td>{signer?.SignerEntry?.SignerWeight}</td>
                                        </tr>
                                    )
                                    })
                                }
                                
                            </tbody>
                        </Table>
                    </td>
                </tr>
                }
            </>
        );    
    } else {
        return <></>;
    }
}

SignerList.propTypes = {
	node: PropTypes.object,
}

export default SignerList;