import React from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import LedgerTag from '../lib/LedgerTag';
import AccountTag from '../lib/AccountTag';
import Loading from '../common/Loading';
import Analytics from '../common/Analytics';


class AccountAncestry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      address: this.props.address,
      ancestry: [],
      loading: false,
      error: null,
    }
  }

  componentDidMount() {
    this.fetchAncestry()
    Analytics.pageview();
  }

  fetchAncestry() {
    this.setState({ loading: true })
    const { address } = this.state

    fetch(`${XRPSCAN_API_URL}/account/${address}/ancestry`)
      .then(handleResponse)
      .then((data) => {
        const { ancestry } = data;

        this.setState({
          ancestry: ancestry,
          loading: false,
        })
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error.errorMessage,
        })
      });
  }

  render() {
    const { address, ancestry, loading } = this.state;

    if (loading) {
      return <Loading />
    }

    return (
      <div>
        <Table responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Activated on</th>
              <th>Account</th>
              <th>Via tx</th>
            </tr>
          </thead>
          <tbody>
            { ancestry.map((ancestor, index) => 
            <tr key={ancestor.depth}>
              <td>
                {index + 1}
              </td>
              <td>
                <span class="text-nowrap">
                  <FormattedDate value={ancestor.inception} timeZone="UTC" year='numeric' month='2-digit' day='2-digit'/>&nbsp;
                  <span className="text-muted">
                    <FormattedTime value={ancestor.inception} timeZone="UTC" hour='2-digit' minute='2-digit' hour12={false}/>
                  </span>
                </span>
              </td>
              <td>
                <span className="text-muted">
                  <AccountTag name={ancestor.accountName} link={!(ancestor.account === address)}>{ancestor.account}</AccountTag>
                </span>
              </td>
              <td>
                <span class="text-nowrap">
                  <span className="address-tag"><Link to={`/tx/${ancestor.tx_hash}`}>{ancestor.tx_hash}</Link></span>
                  <LedgerTag ledger_index={ancestor.ledger_index} />
                </span>
              </td>
            </tr>
            )}
          </tbody>
        </Table>
      </div>
    )
  }
}

AccountAncestry.propTypes = {
  address: PropTypes.string.isRequired,
}

export default AccountAncestry;