import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import XBadge from './XBadge';
import XIcon from './XIcon';

const XIconBadge = (props) => {
  const {
    icon,
    variant,
    faStyle,
    size
  } = props;

  const getFormattedValue = (value) => {
    return Number(value) ? <FormattedNumber value={value} /> : value
  }

  return (
    <XBadge variant={variant}>
      <XIcon icon={icon} faStyle={faStyle} className={`text-${variant}`} size={size}/>
      <span className="text-monospace ml-1">{getFormattedValue(props.children)}</span>
    </XBadge>
  );
}

XIconBadge.defaultProps = {
  icon: 'xmark',
  variant: 'info',
  faStyle: 'fad',
  size: 'md',
}

XIconBadge.propTypes = {
  icon: PropTypes.string,
  variant: PropTypes.string,
  faStyle: PropTypes.string,
  size: PropTypes.string,
}

export default XIconBadge;