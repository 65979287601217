import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';

const Paginator = (props) => {
	const { page, previousMarker, nextMarker, handlePaginationClick } = props;

	if (previousMarker || nextMarker) {
		return (
			<div>
				<Pagination className="justify-content-center">
					<Pagination.First
						onClick={() => handlePaginationClick('prev', previousMarker)}
						disabled={page === 0 ? true : false}>Previous
					</Pagination.First>
					<Button variant="link">Page {page + 1}</Button>
					<Pagination.Last
						onClick={() => handlePaginationClick('next', nextMarker)}
						disabled={nextMarker ? false : true} >Next
					</Pagination.Last>
				</Pagination>
			</div>
		);
	}
	else {
		return null;
	}
}

Paginator.propTypes = {
	page: PropTypes.number.isRequired,
	previousMarker: PropTypes.string.isRequired,
	nextMarker: PropTypes.string.isRequired,
	handlePaginationClick: PropTypes.func.isRequired,
}

export default Paginator;
