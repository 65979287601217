import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import { LedgerEntryType } from '../../common/Helpers';

const Amendments = (props) => {
	const { node } = props;
    if ( node?.LedgerEntryType === LedgerEntryType.Amendments ) {
        return (
            <>
                {node?.Majorities &&
                <tr>
                    <td>Majorities:</td>
                    <td>
                        <Table responsibe>
                           <thead>
                               <tr>
                                    <th>#</th>
                                    <th>Amendment</th>
                                    <th>Close time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {node.Majorities.map((Majority, index) => {
                                    return(
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td><Link to={`/amendment/${Majority?.Majority?.Amendment}`}>{Majority?.Majority?.Amendment}</Link></td>
                                            <td>{Majority?.Majority?.CloseTime}</td>
                                        </tr>
                                    )
                                    })
                                }
                                
                            </tbody>
                        </Table>
                    </td>
                </tr>
                }

                {node?.Amendments &&
                <tr>
                    <td>Amendments:</td>
                    <td>
                        <Table responsibe>
                           <thead>
                               <tr>
                                    <th>#</th>
                                    <th>Amendment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {node.Amendments.map((Amendment, index) => {
                                    return(
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>
                                                <span className='hash'>
                                                    <Link to={`/amendment/${Amendment}`}>{Amendment}</Link>
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                    })
                                }
                            </tbody>
                        </Table>
                    </td>
                </tr>
                }
            </>
        );    
    } else {
        return <></>;
    }
}

Amendments.propTypes = {
	node: PropTypes.object,
}

export default Amendments;