import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { FormattedDate } from 'react-intl';
import LedgerTag from '../lib/LedgerTag';
import Money from '../lib/Money';
import CardTitleContainer from '../lib/CardTitleContainer';


const LedgerInfo = (props) => {
	const {
		ledger,
		error,
		totalFee,
	} = props;

	if (error) {
		return <div className="error">{error}</div>
	}

	return (
	<div>
		<Card className="shadow-sm">
			<Card.Body>
				<Card.Title>
					<CardTitleContainer icon="clipboard-check" title="Ledger summary" />
				</Card.Title>
				<Row>
					<Col xs={12} md={8}>
						<Table responsive>
							<thead>
								<tr>
									<th>Ledger index</th>
									<th>
										<LedgerTag ledger_index={ledger.ledger_index} previous next/>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Ledger hash:</td>
									<td className="hash text-muted">
										<Link to={`/ledger/${ledger.ledger_hash}`}>{ledger.ledger_hash}</Link>
									</td>
								</tr>
								<tr>
									<td>Parent hash:</td>
									<td className="hash text-muted">
										<Link to={`/ledger/${ledger.parent_hash}`}>{ledger.parent_hash}</Link>
									</td>
								</tr>
							{ ledger.account_hash &&
								<tr>
									<td>Accounts hash:</td>
									<td className="hash text-muted">{ledger.account_hash}</td>
								</tr>
							}
								<tr>
									<td>Txs hash:</td>
									<td className="hash text-muted">{ledger.transaction_hash}</td>
								</tr>
							</tbody>
						</Table>
					</Col>

					<Col xs={12} md={4}>
						<Table responsive>
							<thead>
								<tr>
									<th colSpan="2">Properties</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Closed on:</td>
									<td>
									{ ledger.close_time_human &&
										<FormattedDate 
											value={ledger.close_time_human} 
											year='numeric' 
											month='short' 
											day='2-digit' 
											hour='2-digit' 
											minute='2-digit' 
											second='2-digit'
											timeZone='UTC'
											timeZoneName='short'
										/>
									}
									</td>
								</tr>
								<tr>
									<td><abbr title="Total XAH available on-ledger">Total coins</abbr>:</td>
									<td>
									{ ledger.total_coins &&
										<Money value={ledger.total_coins} drops />
									}
									</td>
								</tr>
								<tr className="table-warning">
									<td>&Sigma; Fee:</td>
									<td>
									{ totalFee &&
										<Money value={totalFee} drops />
									}
									</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	</div>
	);
}


LedgerInfo.propTypes = {
	ledger: PropTypes.object.isRequired,
	totalFee: PropTypes.number,
	error: PropTypes.object,	
}

export default LedgerInfo;
