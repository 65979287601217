import React from 'react';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Alert from 'react-bootstrap/Alert';
import { LinkContainer } from 'react-router-bootstrap';
import { FormattedRelative } from 'react-intl';
import { Helmet } from 'react-helmet';
import { XRPSCAN_API_URL, XRPL_SERVER_VERSIONS } from '../../config';
import { handleResponse, getChainStyle } from '../common/Helpers';
import XBadge from '../lib/XBadge';
import XIcon from '../lib/XIcon';
import Callout from '../lib/Callout';
import Money from '../lib/Money';
import Loading from '../common/Loading';
import CardTitleContainer from '../lib/CardTitleContainer';
import Analytics from '../common/Analytics';
import IntegrationGuide from '../common/IntegrationGuide';
import SponsorContainer from '../sponsorship/SponsorContainer';
import TextAd from '../sponsorship/TextAd';
import Skeleton from 'react-loading-skeleton';
import ValidatorTag from '../lib/ValidatorTag';
import NetworkStatus from './NetworkStatus';
import { RESERVE_BASE, RESERVE_INCREMENT } from '../common/Constants';


class ValidatorIndex extends React.Component {
	constructor() {
		super();

		this.state = {
			validators: [],
			nodes: [],
			amendments: {},
			negativeUNL: {},
			serverVersions: [],
			negativeUNLCount: 0,
			reserveBase: RESERVE_BASE,
			reserveIncrement: RESERVE_INCREMENT,
			loading: false,
			error: null,
		}
		this.fetchAmendments = this.fetchAmendments.bind(this);
		this.showAmendment = this.showAmendment.bind(this);
	}

	componentDidMount() {
		this.fetchValidators();
		this.fetchNodes();
		this.fetchFeeSettings();
		this.fetchNegativeUNL();
		this.fetchServerVersions();
    Analytics.pageview();
	}

	fetchAmendments() {
		fetch(`${XRPSCAN_API_URL}/amendments`)
		.then(handleResponse)
		.then((data) => {
			const amendments = {}
			for (const amendment of data) {
				if (amendment.amendment_id && amendment.name) {
					amendments[amendment.amendment_id] = amendment.name;
				}
			}
			this.setState({ amendments: amendments });
		})
		.catch((error) => {
			this.setState({ amendments: {} });
		});
	}

	fetchFeeSettings() {
		fetch(`${XRPSCAN_API_URL}/object/FeeSettings`)
			.then(handleResponse)
			.then((data) => {
				if (data && data.node) {
					this.setState({
						reserveBase: data.node.ReserveBaseDrops,
						reserveIncrement: data.node.ReserveIncrementDrops,
					});
				}
			})
			.catch(error => {
			});
	}

	fetchServerVersions() {
		fetch(`${XRPSCAN_API_URL}/network/server_versions`)
			.then(handleResponse)
			.then((data) => {
				if (data?.length > 0) {
					this.setState({
						serverVersions: data.slice(0, 16),
					});
				}
			})
			.catch(error => {
			});
	}

	fetchNegativeUNL() {
		fetch(`${XRPSCAN_API_URL}/object/NegativeUNL`)
			.then(handleResponse)
			.then((data) => {
				if (data && data.node) {
					const negativeUNL = {}
					if (data.node.ValidatorToDisableHuman) {
						negativeUNL[data.node.ValidatorToDisableHuman] = 'DISABLING';
					}
					if (data.node.ValidatorToReEnableHuman) {
						negativeUNL[data.node.ValidatorToReEnableHuman] = 'REENABLING';
					}
					if (data.node.DisabledValidators) {
						this.setState({ negativeUNLCount: data.node.DisabledValidators.length })
						for (const d of data.node.DisabledValidators) {
							if (d.DisabledValidator && d.DisabledValidator.PublicKeyHuman) {
								negativeUNL[d.DisabledValidator.PublicKeyHuman] = 'DISABLED';
							}
						}
					}
					this.setState({ negativeUNL: negativeUNL });
				}
			})
			.catch(error => {
			});
	}

	fetchValidators() {
		this.setState({ loading: true })
		fetch(`${XRPSCAN_API_URL}/validatorregistry`)
			.then(handleResponse)
			.then((data) => {
				this.setState({
					validators: data || [],
					loading: false,
					error: null,
				});
			})
			.then(this.fetchAmendments)
			.catch((error) => {
				this.setState({
					loading: false,
					error: error.errorMessage,
				});
			});
	}

	fetchNodes() {
		fetch(`${XRPSCAN_API_URL}/nodes`)
			.then(handleResponse)
			.then((data) => {
				this.setState({
					nodes: data || [],
				});
			})
			.catch(() => {
			});
	}

	showAmendment(amendmentId) {
		this.props.history.push(`/amendment/${amendmentId}`);
	}

	getUNLName(unl) {
		switch (unl) {
			case 'vl.xahau.org':
				return 'XRPL Foundation';
			default:
				return '';
		}
	}

	getNUNLName(nunl) {
		switch (nunl) {
			case 'DISABLING':
				return 'nUNL: Adding';
			case 'DISABLED':
				return 'nUNL';
			case 'REENABLING':
				return 'nUNL: Removing';
			default:
				return '';
		}
	}

	getUNLMembership(unls) {
		if (typeof unls === 'object' && unls.length > 0) {
			return (
				<DropdownButton
					id="dropdown-basic-button"
					size="sm"
					variant="link"
					title={<XIcon icon="star" className="text-info"></XIcon>}
					bsPrefix="btn btn-sm btn-votes"
				>
					<Dropdown.Header>
						<XIcon icon="star" className="text-info"></XIcon>
						<span className="ml-2">Recommended by</span>
					</Dropdown.Header>
					{ unls.map(unl => (
						<Dropdown.Item
							key={unl}>
								{this.getUNLName(unl)} ({unl})
						</Dropdown.Item>
					))}
				</DropdownButton>
			)
		}
	}

	getVotedAmendments(votedAmendments) {
		const { amendments } = this.state;
		if (typeof amendments === 'object' && Object.keys(amendments).length > 0) {
			return (
				<DropdownButton
					id="dropdown-basic-button"
					size="sm"
					variant="link"
					title={<XIcon icon="vote-yea"></XIcon>}
					bsPrefix="btn btn-sm btn-votes"
				>
						<Dropdown.Header>
							<XIcon icon="vote-yea" className="text-success"></XIcon>
							<span className="ml-2">Voting in favor of</span>
						</Dropdown.Header>
					{ votedAmendments.map(amendmentId => (
						<Dropdown.Item
							key={amendmentId}
							onClick={this.showAmendment.bind(this, amendmentId)}>
								{amendments[amendmentId]}
						</Dropdown.Item>
					))}
				</DropdownButton>
			)
		}
		else {
			return <Skeleton/>
		}
	}

	getNegativeUNLStatus(validator) {
		const { negativeUNL } = this.state;
		const negativeUNLStatus = negativeUNL[validator];
		let variant = 'primary';
		if (negativeUNLStatus === 'DISABLING') {
			variant = 'warning';
		}
		else if (negativeUNLStatus === 'DISABLED') {
			variant = 'danger';
		}
		else if (negativeUNLStatus === 'REENABLING') {
			variant = 'success';
		}

		return (
			<XBadge variant={variant} className="ml-1">
				<XIcon icon="minus-circle" className="mr-1"/>{this.getNUNLName(negativeUNLStatus)}
			</XBadge>
		)
	}

	getStaleValidatorStyle(last_seen, inNegativeUNL) {
		if (inNegativeUNL || (last_seen && (Date.now() - Date.parse(last_seen) > 3600000))) {
			return 'table-warning';
		}
		else {
			return '';
		}
	}

	getReserveVoteIcon(networkValue, votedValue) {
		return (networkValue < votedValue) ? 'chevron-double-up' : 'chevron-double-down';
	}

	render() {
		const {
			validators,
			nodes,
			loading,
			reserveBase,
			reserveIncrement,
			negativeUNL,
			serverVersions,
		} = this.state;

		// render only loading component if loading state is set to true
		if (loading) {
			return <Loading />
		}

		return (
			<div>
				<Helmet>
					<title>Xahau Validator registry | XAHSCAN</title>
				</Helmet>
				<Row>
					<Col>
						<IntegrationGuide/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={4}>
						<Breadcrumb>
							<LinkContainer to={`/`}><Breadcrumb.Item>Home</Breadcrumb.Item></LinkContainer>
							<Breadcrumb.Item active>Validators</Breadcrumb.Item>
						</Breadcrumb>
					</Col>
					<Col xs={12} md={8}>
						<SponsorContainer />
					</Col>
				</Row>

				<NetworkStatus
					validators={validators}
					nodes={nodes}
					negativeUNLCount={this.state.negativeUNLCount}
					reserveBase={reserveBase}
					reserveIncrement={reserveIncrement}
					serverVersions={serverVersions}
				/>

				<Row>
				  <Col xs={12} md={12}>
				    <TextAd/>
					</Col>
				</Row>

				<Card>
					<Card.Body>
						<Row>
							<Col xs={12} md="12">
								<Callout title="NOTE" variant="info">
									<span className="ml-2">Validators listed in this registry are based on validations received at flag ledgers.
									To verify your validator's identity, please set up <Alert.Link href="https://xrpl.org/xrp-ledger-toml.html#domain-verification" target="_blank">domain verification &rarr;</Alert.Link></span>
								</Callout>
							</Col>
						</Row>
						<Card.Title>
							<CardTitleContainer icon="server" title="Validator registry" />
						</Card.Title>
						<Table responsive>
							<thead>
								<tr>
									<th className="text-right">#</th>
									<th className="text-center">Chain</th>
									<th className="text-center">UNL</th>
									<th className="text-left">Validator</th>
									<th className="text-right">Base</th>
									<th className="text-right">Owner</th>
									<th className="text-center">Amendments</th>
									<th className="text-center">Version</th>
									<th className="text-right">Last seen</th>
								</tr>
							</thead>
							<tbody>
								{ validators.map((validator, index) => (
								<tr key={validator.master_key} className={this.getStaleValidatorStyle(validator.last_seen, validator.master_key in negativeUNL)}>
									<td className="text-right"><span>{index+1}</span></td>
									<td className="text-center"><XBadge variant={getChainStyle(validator.chain)} className="text-uppercase">{validator.chain}</XBadge></td>
									<td>
										{this.getUNLMembership(validator.unl)}
									</td>
									<td className="text-left">
										<ValidatorTag
											validator={validator}
										>
											{validator.master_key}
										</ValidatorTag>
										{negativeUNL[validator.master_key] &&
											this.getNegativeUNLStatus(validator.master_key)
										}
									</td>
									<td className="text-right">
										{validator.votes && validator.votes.reserve_base ?
											<XBadge variant="primary">
												<XIcon icon={this.getReserveVoteIcon(reserveBase, validator.votes.reserve_base)} className="mr-2"/>
												<Money value={validator.votes.reserve_base} drops />
											</XBadge>
											:
											<XBadge variant="light">
												<Money value={reserveBase} drops />
											</XBadge>
										}
									</td>
									<td className="text-right">
										{validator.votes && validator.votes.reserve_inc ?
											<XBadge variant="primary">
												<XIcon icon={this.getReserveVoteIcon(reserveIncrement, validator.votes.reserve_inc)} className="mr-2"/>
												<Money value={validator.votes.reserve_inc} drops/>
											</XBadge>
											:
											<XBadge variant="light">
												<Money value={reserveIncrement} drops />
											</XBadge>
										}
									</td>
									<td className="text-center">
										{validator.votes && validator.votes.amendments && validator.votes.amendments.length > 0 &&
											<>{this.getVotedAmendments(validator.votes.amendments)}</>
										}
									</td>
									<td className="text-center">
										{validator.server_version && validator.server_version.version &&
											<XBadge variant={XRPL_SERVER_VERSIONS.includes(validator.server_version.version) ? 'success' : 'warning'}>{validator.server_version.version}</XBadge>
										}
									</td>
									<td className="text-right">
										<span className="text-nowrap"><FormattedRelative value={validator.last_seen}/></span>
									</td>
								</tr>								
								))}
							</tbody>
						</Table>
					</Card.Body>
				</Card>
			</div>
		);		
	}
}

export default ValidatorIndex;