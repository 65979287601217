import React from 'react';
import PropTypes from 'prop-types';
import { getTxTypeStyle, getTxTypeLabel } from '../common/Helpers';
import XBadge from './XBadge';

const TxType = (props) => {
	const { type } = props;
	return (
		<XBadge variant={ getTxTypeStyle( type ) }>{ getTxTypeLabel( type ) }</XBadge>
	)
}

TxType.propTypes = {
	type: PropTypes.string.isRequired,
}

export default TxType;