import React, { useState } from "react";
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { 
  useTable,
  useFilters,
  usePagination
} from "react-table";
import XIcon from '../lib/XIcon';

// https://xrpscan.com/account/rupiahgZvKp1qaP6hqAqAZ3A44ARcnGSM
// https://xrpscan.com/account/rXRP4WibqvXLfCioHBDAt5rWKotaQv5N5

const defaultPropGetter = () => ({})

const AdvancedTable = ({
  columns,
  data,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  filterProperty = defaultPropGetter,
  filterPlaceholder = defaultPropGetter,
  defaultPageSizes = defaultPropGetter,
  initialPageSize = defaultPropGetter,
}) => {
  const pageSizes = ( data && data.length > defaultPageSizes.slice(-1)) ? [...defaultPageSizes, data.length] : defaultPageSizes;
  const [ filterInput, setFilterInput ] = useState("");
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({
      columns,
      data,
      initialState: { 
        pageIndex: 0,
        pageSize: initialPageSize,
       },
    },
    useFilters,
    usePagination,
  );

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter(filterProperty, value);
    setFilterInput(value);
  }

  return(
    <>
      {filterProperty && filterProperty.length > 0 &&
      <div className="mt-2">
        <Form.Control
          value={filterInput}
          onChange={handleFilterChange}
          placeholder={filterPlaceholder}
        />
      </div>
      }
      <Table responsive {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps([
                  {
                    className: column.className,
                    style: column.style,
                  },
                  getColumnProps(column),
                  getHeaderProps(column),
                  ])}>
                  {column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps([
                    {
                      className: cell.column.className,
                      style: cell.column.style,
                    },
                    getColumnProps(cell.column),
                    getCellProps(cell),
                    ])}>
                    {cell.render("Cell")}</td>
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      {/* Pagination */}

      <Container fluid>
        <Row>
          <Col xs={12} md={6}>
            <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage} variant="outline-info" className="mr-1">
              <XIcon icon="chevron-double-left"></XIcon>
            </Button>
            <Button onClick={() => previousPage()} disabled={!canPreviousPage} variant="outline-info" className="ml-1 mr-1">
              <XIcon icon="chevron-left"></XIcon>
            </Button>
            <span className="ml-2 mr-2">
              Page {pageIndex + 1} of {pageOptions.length}
            </span>
            <Button onClick={() => nextPage()} disabled={!canNextPage} variant="outline-info" className="ml-1 mr-1">
              <XIcon icon="chevron-right"></XIcon>
            </Button>
            <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} variant="outline-info" className="ml-1">
              <XIcon icon="chevron-double-right"></XIcon>
            </Button>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group as={Row} controlId="formGroupPage">
              <Col xs={12} md={6}>
                <Form.Control
                  type="number"
                  placeholder="Go to page"
                  defaultValue={pageIndex + 1}
                  onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(page)
                  }}
                />
              </Col>
              <Col xs={12} md={6}>
              <Form.Control
                as="select"
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
                >
                {pageSizes.map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
            </Form.Control>
              </Col>
            </Form.Group>
          </Col>
          <Col md="2">
          </Col>
        </Row>
      </Container>
    </>
  )
}

AdvancedTable.defaultProps = {
  filterPlaceholder: "Search",
  defaultPageSizes: [25, 50, 100, 1000],
  initialPageSize: 25,
}

AdvancedTable.propTypes = {
	filterProperty: PropTypes.string,
  filterPlaceholder: PropTypes.string,
  defaultPageSizes: PropTypes.arrayOf(PropTypes.number),
  initialPageSize: PropTypes.number,
}

export default AdvancedTable;