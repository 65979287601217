import React from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';
import { FormattedDate } from 'react-intl';
import { rippleTime2UnixTime } from '../common/Helpers';
import Money from '../lib/Money';
import AccountTag from '../lib/AccountTag';
import LedgerTag from '../lib/LedgerTag';
import XIcon from '../lib/XIcon';


const AccountEscrow = (props) => {
  const { escrows, account } = props;

  return (
    <div>
      <Table responsive>
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th>Tx hash</th>
            <th>Owner</th>
            <th></th>
            <th>Destination</th>
            <th className="text-right">Amount</th>
            <th className="text-right">Finish after</th>
            <th className="text-right"><XIcon icon='lock' /></th>
          </tr>
        </thead>
        <tbody>
          { escrows.map((escrow, index) =>
          <tr key={index}>
            <td className="text-center">{index+1}</td>
            <td>
              <span className="address-tag"><Link to={`/tx/${escrow.PreviousTxnID}`}>{escrow.PreviousTxnID}</Link></span>
              <LedgerTag ledger_index={escrow.PreviousTxnLgrSeq} />
            </td>
            <td>
              <span className="text-muted">
                <AccountTag name={escrow.AccountName} link={!(escrow.Account === account.account)} minimal st={escrow.SourceTag}>{escrow.Account}</AccountTag>
              </span>
            </td>
            <td>
              {escrow.Account === escrow.Destination ?
                <Badge variant="success">SELF</Badge>
                : escrow.Account === account.account ?
                  <Badge variant="warning">OUT</Badge>
                  : <Badge variant="success">&nbsp;&nbsp;IN&nbsp;&nbsp;</Badge>
              }
            </td>
            <td>
              <span className="text-muted">
                <AccountTag name={escrow.DestinationName} link={!(escrow.Destination === account.account)} minimal dt={escrow.DestinationTag}>{escrow.Destination}</AccountTag>
              </span>
            </td>
            <td className="text-right">
              <Money
                value={escrow.Amount}
                drops
              />
            </td>
            <td className="text-right">
              {escrow.FinishAfter &&
                <FormattedDate value={ rippleTime2UnixTime(escrow.FinishAfter) * 1000 } />
              }
            </td>
            <td className="text-right">
              {rippleTime2UnixTime(escrow.FinishAfter) < (Date.now()/1000) ?
                <XIcon icon="lock-open" className="text-success"/>
                : <XIcon icon="lock" className="text-warning"/>
              }
            </td>
          </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

AccountEscrow.propTypes = {
  escrows: PropTypes.array.isRequired,
  account: PropTypes.object.isRequired,
}

export default AccountEscrow;