import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import XIcon from '../lib/XIcon';
import XBadge from '../lib/XBadge';
import ValidatorTag from '../lib/ValidatorTag';


const AmendmentVotes = (props) => {
  const { amendment, error } = props;

  if (error) {
    return <div className="error">{error}</div>
  }

  return (
  <div>
    <Row>
      <Col xs={12} md={6}>
        <Card border="success">
          <Card.Body>
            <Card.Title>
              <XIcon icon='vote-yea' className="text-success"/>
              <span className="pl-2">Yeas</span>
            </Card.Title>
            <Table responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Validator</th>
                </tr>
              </thead>
              <tbody>
                { amendment.voters && amendment.voters.length === 0 &&
                <tr>
                  <td colSpan="3" className="text-center">
                    <XBadge variant="primary">UNANIMOUS DISSENT</XBadge>
                  </td>
                </tr>
                }
                { amendment.voters.sort((a,b) => {return b.domain < a.domain}).map((voter, index) => (
                <tr key={voter.master_key}>
                  <td><span>{index+1}</span></td>
                  <td><span className="hash"><ValidatorTag validator={voter} verificationIcon={false}>{voter.master_key}</ValidatorTag></span></td>
                </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} md={6}>
        <Card border="danger">
          <Card.Body>
            <Card.Title>
              <XIcon icon='vote-nay' className="text-danger"/>
              <span className="pl-2">Nays</span>
            </Card.Title>
            <Table responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Validator</th>
                </tr>
              </thead>
              <tbody>
                { amendment.vetoers && amendment.vetoers.length === 0 &&
                <tr>
                  <td colSpan="3" className="text-center">
                    <XBadge variant="primary">UNANIMOUS VOTE</XBadge>
                  </td>
                </tr>
                }
                { amendment.vetoers.sort((a,b) => {return b.domain < a.domain}).map((voter, index) => (
                <tr key={voter.master_key}>
                  <td><span>{index+1}</span></td>
                  <td><span className="hash"><ValidatorTag validator={voter} verificationIcon={false}>{voter.master_key}</ValidatorTag></span></td>
                </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </div>
  );
}


AmendmentVotes.propTypes = {
  amendment: PropTypes.object.isRequired,
  error: PropTypes.object,
}

export default AmendmentVotes;
