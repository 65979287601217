import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import NodeInfo from './NodeInfo';
import Analytics from '../common/Analytics';


class Node extends React.Component {
	constructor() {
		super();

		this.state = {
			node: {},
			loading: false,
			error: null,
		}
	}

	componentDidMount() {
		const node_public_key = this.props.match.params.node_public_key;
		this.fetchNode(node_public_key);
    Analytics.pageview();
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.location.pathname !== nextProps.location.pathname) {
			const newNodePublicKey = nextProps.match.params.hash;
			this.fetchNode(newNodePublicKey);
	    Analytics.pageview();
		}
	}

	fetchNode(node_public_key) {
		this.setState({ loading: true })

		fetch(`${XRPSCAN_API_URL}/topology/node/${node_public_key}`)
			.then(handleResponse)
			.then((data) => {
				this.setState({
					loading: false,
					node: data,
					error: null,
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error.errorMessage,
				});
			});
	}

	render() {
		const { node, loading } = this.state;

		// render only loading component if loading state is set to true
		if (loading) {
			return <div className="loading-container">Loading...</div>
		}

		return (
			<div>
				<Row>
					<Col xs={12} md={12}>
						<NodeInfo node={node} />
					</Col>
				</Row>
			</div>
		)
	}
}

export default Node;