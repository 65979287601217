import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types';

const EmptyStateNoRecord = (props) => {
  const { title, variant } = props;
  const description = props.children;

  return (
    <Row>
      <Col xs={12} md={12}>
      <Card border={variant} className="text-center">
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text>{description}</Card.Text>
        </Card.Body>
      </Card>
      </Col>
    </Row>
  );
}

EmptyStateNoRecord.defaultProps = {
  variant: 'info',
}

EmptyStateNoRecord.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.string,
}

export default EmptyStateNoRecord;