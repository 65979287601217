import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Skeleton from 'react-loading-skeleton';
import { FormattedNumber } from 'react-intl';
import XIcon from '../lib/XIcon';


const getMetricValue = (m,u='') => {
  if (m) {
    return (
      <span>
        <span className="text-monospace"><FormattedNumber value={m} maximumFractionDigits={2} /></span>
        <small className="text-muted pl-1">{u}</small>
      </span>
    );
  }
  else {
    return m === 0 ? 0 : <Skeleton />;
  }
}

const MetricCard = (props) => {
  const { metric, unit, icon } = props;

  return (
     <Card className="text-center border-0">
      <Card.Body className="metric-card">
        <h5><XIcon icon={icon} /></h5>
        <h5 className="metric-card-number">
          { getMetricValue(metric, unit) }
        </h5>
        <Card.Subtitle className="text-muted metric-card-number">{props.children}</Card.Subtitle>
      </Card.Body>
    </Card>
  );
}

MetricCard.propTypes = {
  metric: PropTypes.number,
  unit: PropTypes.string,
  icon: PropTypes.string,
};

export default MetricCard;