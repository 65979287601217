import ReactGA from 'react-ga4';

class Analytics {
  static pageview = (url = null) => {
    const page = url || window?.location;
    const title = document?.title || "";
    ReactGA.send({ hitType: "pageview", page: page, title: title });
  }

  static event = (params) => {
    ReactGA.event(params);
  }
}

export default Analytics;