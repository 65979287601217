import React from 'react';
import Alert from 'react-bootstrap/Alert';

const Notice = () => {
	return (
    <div>
      <Alert variant={'info'}>
        Your gateway to the Xahau Ledger - The home of XAH
      </Alert>
    </div>
	);
}

export default Notice;
