import React from 'react';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import XBadge from '../lib/XBadge';
import { getRandomElement } from '../common/Helpers';
import nebeusLogo from '../../assets/images/nebeus.png';

const adVariations = [
  {
    label: <>Learn more &rarr;</>,
    title: <>Get your IBAN for managing your FIAT &amp; investing in Crypto.</>,
    href: "https://api.xrpscan.com/api/v1/campaign/nebeustext2",
  },
  {
    label: <>Get your IBAN now &rarr;</>,
    title: <>IBAN Accounts for Crypto Hodlers. Just like a Bank.</>,
    href: "https://api.xrpscan.com/api/v1/campaign/nebeustext3",
  },
];
const adVariation = getRandomElement(adVariations);

const TextAd = () => {
  return (
    <div>
      <Card className="shadow-sm" border="info">
        <Card.Body>
          <XBadge variant="light"><small>Sponsored:</small></XBadge>
          <Image alt="Nebeus" src={nebeusLogo} height="24" className="ml-1" />
          <span className="ml-1">{adVariation.title}</span>
          <span className="ml-2"><a href={adVariation.href} target="_blank" rel="noopener noreferrer">{adVariation.label}</a></span>
        </Card.Body>
      </Card>
    </div>
  );
}

export default TextAd;
