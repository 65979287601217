import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LedgerEntryType } from '../../common/Helpers';
import { getFlagTag, parseNFTokenOfferFlags } from '../../common/FlagHelpers';

const NFTokenOffer = (props) => {
	const { node } = props;
    if ( node?.LedgerEntryType === LedgerEntryType.NFTokenOffer ) {
        return (
            <>
                {node?.NFTokenID &&
                <tr>
                    <td>NFTokenID:</td>
                    <td>
                        <span>
                            <Link to={`/nft/${node.NFTokenID}`}>{node.NFTokenID}</Link>
                        </span>
                    </td>
                </tr>
                }

                {node?.NFTokenOfferNode >= 0 &&
                <tr>
                    <td>NFTokenOfferNode:</td>
                    <td>
                        <span>
                           {node.NFTokenOfferNode}
                        </span>
                    </td>
                </tr>
                }

                {node?.Flags > 0 &&
                <tr>
                    <td>Flags:</td>
                    <td>
                        {getFlagTag(node.Flags, parseNFTokenOfferFlags)}
                    </td>
                </tr>
                }
            </>
        );    
    } else {
        return <></>;
    }
}

NFTokenOffer.propTypes = {
	node: PropTypes.object,
}

export default NFTokenOffer;