import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'react-bootstrap/Badge';
import { getTxResultStyle, getTxResultLabel } from '../common/Helpers';


const TxResult = (props) => {
	const { result } = props;
	return (
		<Badge variant={ getTxResultStyle( result ) }>{ getTxResultLabel( result ) }</Badge>
	)
}

TxResult.propTypes = {
	result: PropTypes.string.isRequired,
}

export default TxResult;