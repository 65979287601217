import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import XIcon from '../lib/XIcon';

const getQueryString = (queryParams) => {
  if (queryParams && Object.keys(queryParams).length > 0) {
    return Object
    .entries(queryParams)
    .map((qp) => qp.join('='))
    .join('&');
  }
  else {
    return '';
  }
}

const DeepSearchTag = (props) => {
  const queryString = getQueryString(props);

  return (
    <>
      <Link to={`/search?${queryString}`}>
        <XIcon icon="magnifying-glass-plus" size="1x" faStyle="far" className="text-info"/>
      </Link>
    </>
  )
}

DeepSearchTag.propTypes = {
  Account: PropTypes.string,
  SourceTag: PropTypes.number,
  Destination: PropTypes.string,
  DestinationTag: PropTypes.number,
  TransactionType: PropTypes.string,
}

export default DeepSearchTag;