import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'react-bootstrap/Badge';
import Alert from 'react-bootstrap/Alert';


const Callout = (props) => {
  const { title, variant } = props;

  let calloutTitle = null;
  if (title && typeof title === 'string') {
    calloutTitle = <span><Badge variant={variant} className="badge-large">{title}</Badge></span>;
  }

  return (
    <Alert variant={variant}>
      {calloutTitle}
      {props.children}
    </Alert>
  );
}

Callout.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.string,
}

export default Callout;