import React from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import Loading from '../common/Loading';
import Paginator from '../lib/Paginator';
import LedgerTag from '../lib/LedgerTag';
import Money from '../lib/Money';
import AccountTag from '../lib/AccountTag';
import Analytics from '../common/Analytics';


class AccountActivation extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			address: this.props.address,
			accounts: [],
			marker: null,
			markers: [],
			page: 0,
			loading: false,
			error: null,
		}

		this.handlePaginationClick = this.handlePaginationClick.bind(this);
	}

	componentDidMount() {
		this.fetchActivations()
		Analytics.pageview();
	}

	fetchActivations(nextMarker=null) {
		this.setState({ loading: true })
		const query_string = nextMarker ? `?marker=${nextMarker}` : ''
		const { address } = this.state

		fetch(`${XRPSCAN_API_URL}/account/${address}/activations${query_string}`)
			.then(handleResponse)
			.then((data) => {
				const { accounts, marker } = data
				const { markers, page } = this.state
				if (marker) markers[page + 1] = marker

				this.setState({
					accounts: accounts,
					marker: marker,
					markers: markers,
					loading: false,
				})
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error.errorMessage,
				})				
			});
	}

	handlePaginationClick(direction, nextMarker) {
		const { page }  = this.state;

		if (direction === 'next') {
			this.setState({ page: page + 1 },	() => {
				this.fetchActivations(nextMarker);
			});
		}
		else {
			this.setState({	page: page - 1 },	() => {
				this.fetchActivations(nextMarker);
			});
		}
	}

	render() {
		const { accounts, loading, page, markers } = this.state;
		const previousMarker = markers[page - 1]
		const nextMarker = markers[page + 1]

		if (loading) {
			return <Loading />
		}

		return (
			<div>
				<Table responsive>
					<thead>
						<tr>
							<th className="text-nowrap">Activated on</th>
							<th className="text-nowrap">Tx hash</th>
							<th className="text-nowrap">Account</th>
							<th className="text-right text-nowrap">With balance</th>
						</tr>
					</thead>
					<tbody>
						{ accounts.map((account) => 
						<tr key={account.account}>
							<td>
								<span class="text-nowrap">
									<FormattedDate value={account.inception} timeZone="UTC" year='numeric' month='2-digit' day='2-digit'/>&nbsp;
									<span className="text-muted">
										<FormattedTime value={account.inception} timeZone="UTC" hour='2-digit' minute='2-digit' hour12={false}/>
									</span>
                </span>
							</td>
							<td>
								<span class="text-nowrap">
									<span className="address-tag"><Link to={`/tx/${account.tx_hash}`}>{account.tx_hash}</Link></span>
									<LedgerTag ledger_index={account.ledger_index} />
								</span>
							</td>
							<td>
								<AccountTag name={account.accountName} link>{account.account}</AccountTag>
							</td>
							<td className="text-right">
								<Money value={account.initial_balance} />
							</td>
						</tr>
						)}
					</tbody>
				</Table>
				<Paginator
					page={page}
					previousMarker={previousMarker}
					nextMarker={nextMarker}
					handlePaginationClick={this.handlePaginationClick}
				/>
			</div>
		)
	}
}

AccountActivation.propTypes = {
	address: PropTypes.string.isRequired,
}

export default AccountActivation;