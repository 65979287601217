import React from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import XBadge from '../lib/XBadge';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { getChainStyle } from '../common/Helpers';
import XIcon from '../lib/XIcon';
import ValidatorTag from '../lib/ValidatorTag';


const ValidatorList = (props) => {
	const { validators, error } = props;

	const getSkeleton = () => {
		let rows = [];
		for (let i = 0; i < 9; i++) {
			rows.push(<tr key={i}><td colSpan={3}><Skeleton/></td></tr>);
		}
		return rows;
	}

	if (error) {
		return <div className="error">{error}</div>
	}

	return (
		<div>
			<Card>
				<Card.Body>
					<Card.Title>
						<XIcon icon='server' />
						<span className="pl-2">Validators</span>
				    <span className="float-right">
				    	<Link to={`/validators`}>Registry &rarr;</Link>
			    	</span>
					</Card.Title>
					<Table responsive>
						<thead>
							<tr>
								<th className="text-center"><span className="text-muted">UNL</span></th>
								<th className="text-left"><span className="text-muted">Validator</span></th>
								<th className="text-center"><span className="text-muted">Chain</span></th>
							</tr>
						</thead>
						<tbody>
							{ validators && validators.length === 0 &&
								getSkeleton()
							}
							{ validators.map((validator) => (
							<tr key={validator.master_key}>
								<td className="text-center">
									{ validator.unl && validator.unl.length > 0 && validator.unl.includes('vl.xahau.org') &&
										<XBadge variant="info">XRPLF</XBadge>
									}
								</td>
								<td className="text-left">
									<ValidatorTag
										validator={validator}
									>
										{validator.master_key}
									</ValidatorTag>
								</td>
								<td className="text-center"><XBadge variant={getChainStyle(validator.chain)} className="text-uppercase">{validator.chain}</XBadge></td>
							</tr>
							))}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
		</div>
	);
}

ValidatorList.propTypes = {
	validators: PropTypes.array.isRequired,
	error: PropTypes.object,
};

export default ValidatorList;