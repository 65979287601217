import React from 'react';
import PropTypes from 'prop-types';
import { LedgerEntryType } from '../../common/Helpers';
import XBadge from '../../lib/XBadge';

const FeeSettings = (props) => {
	const { node } = props;
    if ( node?.LedgerEntryType === LedgerEntryType.FeeSettings ) {
        return (
            <>
                {node?.BaseFeeDrops &&
                <tr>
                    <td>BaseFeeDrops:</td>
                    <td>
                        {node.BaseFeeDrops}
                    </td>
                </tr>
                }

                {node?.ReferenceFeeUnits >= 0 &&
                <tr>
                    <td>ReferenceFeeUnits:</td>
                    <td>
                        <span>
                            {node.ReferenceFeeUnits}
                        </span>
                    </td>
                </tr>
                }

                {node?.ReserveBaseDrops &&
                <tr>
                    <td>ReserveBaseDrops:</td>
                    <td>
                        <span>
                            {node.ReserveBaseDrops}
                        </span>
                    </td>
                </tr>
                }

                {node?.ReserveIncrementDrops &&
                <tr>
                    <td>ReserveIncrementDrops:</td>
                    <td>
                        <span>
                            {node.ReserveIncrementDrops}
                        </span>
                    </td>
                </tr>
                }

                {node?.AccountCount &&
                <tr>
                    <td>AccountCount:</td>
                    <td>
                        <span>
                            {parseInt(node.AccountCount, 16)}
                            <XBadge variant="dark" className="ml-2 text-monospace text-uppercase">HEX: {node.AccountCount}</XBadge>
                        </span>
                    </td>
                </tr>
                }
                {node?.NetworkID &&
                <tr>
                    <td>NetworkID:</td>
                    <td>
                        <span>
                            {node.NetworkID}
                        </span>
                    </td>
                </tr>
                }
                {node?.XahauActivationLgrSeq &&
                <tr>
                    <td>XahauActivationLgrSeq:</td>
                    <td>
                        <span>
                            {node.XahauActivationLgrSeq}
                        </span>
                    </td>
                </tr>
                }
            </>
        );    
    } else {
        return <></>;
    }
}

FeeSettings.propTypes = {
	node: PropTypes.object,
}

export default FeeSettings;