import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import LedgerTag from '../lib/LedgerTag';
import AccountTag from '../lib/AccountTag';
import Callout from '../lib/Callout';
import CardTitleContainer from '../lib/CardTitleContainer';
import XBadge from '../lib/XBadge';
import XIcon from '../lib/XIcon';
import { NFTokenMintFlags } from '../common/Constants';
import XIconBadge from '../lib/XIconBadge'
import { transferFeeToPercent } from '../common/Helpers'

const NFTokenInfo = (props) => {
	const { nftoken } = props;

	const getNFTokenStyle = () => {
		return (nftoken && nftoken.is_burned) ? 'danger' : '';
	}

	return (
		<Card className="shadow-sm" border={getNFTokenStyle()}>
			<Card.Body>

				{nftoken && nftoken.is_burned &&
				<Row>
					<Col xs={12} md={12}>
						<Callout title="BURNED" variant='danger'>
							&nbsp; This NFT is unavailable. It was burned by its owner or issuer.
						</Callout>
					</Col>
				</Row>
				}

				<Card.Title>
					<CardTitleContainer icon="hexagon-vertical-nft" title="NFT summary" />
				</Card.Title>
				<Row>
					<Col xs={12} md={8}>
						<Table responsive>
							<thead>
								{nftoken && nftoken.nft_id &&
								<tr>
									<th className="data-header text-nowrap">NFT ID:</th>
									<th><span className='text-muted'>{nftoken.nft_id}</span></th>
								</tr>
								}
							</thead>

							<tbody>
								{nftoken && nftoken.owner &&
								<tr>
									<td>Owner:</td>
									<td><AccountTag link>{nftoken.owner}</AccountTag></td>
								</tr>
								}
								{nftoken && nftoken.issuer &&
								<tr>
									<td>Issuer:</td>
									<td><AccountTag link>{nftoken.issuer}</AccountTag></td>
								</tr>
								}
								
								{nftoken && nftoken.uri &&
								<tr>
									<td>URI:</td>
									<td>{nftoken.uri}</td>
								</tr>
								}

								{nftoken && nftoken.flags_parsed && nftoken.flags_parsed.length > 0 &&
								<tr>
									<td>Flags:</td>
									<td colSpan={2}>
										{nftoken.flags_parsed.includes(NFTokenMintFlags.tfBurnable) &&
											<XBadge variant="primary" className="text-uppercase mr-1"><XIcon icon="fire-alt" className="mr-1"/> Burnable</XBadge>
										}
										{nftoken.flags_parsed.includes(NFTokenMintFlags.tfTransferable) &&
											<XBadge variant="primary" className="text-uppercase ml-1 mr-1"><XIcon icon="arrows-repeat" className="mr-1"/>Transferable</XBadge>
										}
										{nftoken.flags_parsed.includes(NFTokenMintFlags.tfOnlyXRP) &&
											<XBadge variant="primary" className="text-uppercase ml-1 mr-1"><XIcon icon="xmark" className="mr-1"/>Only XAH</XBadge>
										}
										{nftoken.flags_parsed.includes(NFTokenMintFlags.tfTrustLine) &&
											<XBadge variant="primary" className="text-uppercase ml-1"><XIcon icon="user-plus" className="mr-1"/>TrustLine</XBadge>
										}
									</td>
								</tr>
								}

								{nftoken && nftoken.nft_id &&
								<tr>
									<td className="text-nowrap">View in:</td>
									<td className="text-nowrap"><a href={`https://xumm.app/detect/xapp:nftltd.viewer?NFTokenID=${nftoken.nft_id}`} rel="nofollow noopener noreferrer">Non Fungible xApp</a></td>
								</tr>
								}
							</tbody>
						</Table>
					</Col>

					<Col xs={12} md={4}>
						<Table responsive>
							<thead>
								<tr>
									<th colSpan="2">Properties</th>
								</tr>
							</thead>
							<tbody>
								{nftoken && Number(nftoken.nft_taxon) >= 0 &&
								<tr>
									<td>Taxon:</td>
									<td>
										<XIconBadge variant="success" icon="layer-group">{nftoken.nft_taxon}</XIconBadge>
									</td>
								</tr>
								}

								{nftoken && Number(nftoken.nft_sequence) >= 0 &&
								<tr>
									<td>Serial:</td>
									<td>
										<XIconBadge variant="primary" icon="hashtag">{nftoken.nft_sequence}</XIconBadge>
									</td>
								</tr>
								}

								{nftoken && Number(nftoken.transfer_fee) >= 0 &&
								<tr>
									<td>Transfer fee:</td>
									<td>
										{transferFeeToPercent(nftoken.transfer_fee)}%
									</td>
								</tr>
								}

								{nftoken && nftoken.ledger_index &&
								<tr>
									<td>Last modified:</td>
									<td><LedgerTag ledger_index={nftoken.ledger_index} /></td>
								</tr>
								}

							</tbody>
						</Table>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
}

NFTokenInfo.propTypes = {
	nftoken: PropTypes.object.isRequired,
	error: PropTypes.object,
}

export default NFTokenInfo;
