import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet';
import Notice from '../common/Notice';
import TransactionSummary from './TransactionSummary';
import LedgerList from '../ledger/LedgerList';
import ValidatorList from '../validator/ValidatorList';
import GovernanceSummary from './GovernanceSummary';
import BannerAd from '../sponsorship/BannerAd';
import SponsorContainer from '../sponsorship/SponsorContainer';
import { XRPSCAN_API_URL, XRPSCAN_WSS_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import socketio from 'socket.io-client';
import Analytics from '../common/Analytics';
import { TOTAL_COIN_SUPPLY } from '../common/Constants';

class Home extends React.Component {
	constructor() {
		super();

		const socket = socketio(XRPSCAN_WSS_URL, {
			autoConnect: false,
			// transports: ["polling"]
		});

		this.state = {
			socket: socket,
			current_ledger: null,
			ledgers: [],
			ledger_error: null,
			validators: [],
			validator_error: null,
			stats: [],
			stats_error: null,
			ledger_index: null,
			payments_count: null,
			transaction_count: null,
			accounts_created: null,
			ledger_interval: null,
			tx_per_second: null,
			xrp_destroyed: null,
		};
	}

	componentDidMount() {
		this.fetchLedgers();
		this.fetchValidators();
		this.fetchStats();
		if (this.state.socket.disconnected) {
			this.state.socket.connect();
		}
		this.state.socket.on('ledger:new', (ledger) => this.updateLedgers(ledger));
		Analytics.event({category: 'home', action: 'Homepage'});
	}

	componentWillUnmount() {
		clearInterval(this.timer);
		this.timer = null;
		if (this.state.socket && this.state.socket.connected) {
			this.state.socket.disconnect();
		}
	}

	fetchLedgers() {
		fetch(`${XRPSCAN_API_URL}/ledgers`)
			.then(handleResponse)
			.then((data) => {
				const { current_ledger, ledgers } = data;
				if (this.state.current_ledger !== current_ledger) {
					this.setState({ ledgers, current_ledger	});
					this.setState({ ledger_index: current_ledger });
					this.setState({ tx_per_second: this.getTxPerSecond(ledgers) });
				}
				if (ledgers && ledgers.length > 0 && ledgers[0] && ledgers[0].total_coins > 0) {
					const xrp_destroyed = TOTAL_COIN_SUPPLY - (ledgers[0].total_coins / 1000000);
					this.setState({ xrp_destroyed });
				}
			})
			.catch((error) => {
				this.setState({
					ledger_error: error.errorMessage,
				});
			});
	}

	getTxPerSecond(ledgers) {
		if (ledgers.length > 1) {
			const newest_ledger = ledgers[0];
			const oldest_ledger = ledgers[ledgers.length - 1];
			const total_tx = ledgers.reduce((accumulator, ledger) => { return accumulator + ledger.tx_count }, 0);
			const duration = (new Date( newest_ledger.timestamp || newest_ledger.close_time_human )) -
											 (new Date( oldest_ledger.timestamp || oldest_ledger.close_time_human ))
			return total_tx / (duration / 1000);
		}
	}

	updateLedgers(ledger) {
		var { ledgers } = this.state;
		if (ledgers.length > 0) {
			ledger.destroyed_coins = ledger.total_coins - ledgers[0].total_coins;
			ledger.xrp_destroyed = TOTAL_COIN_SUPPLY - (ledger.total_coins / 1000000);
		}
		ledgers.pop();
		ledgers.unshift(ledger);
		this.setState({ ledgers });
		this.setState({ ledger_index: ledger.ledger_index});
		this.setState({ tx_per_second: this.getTxPerSecond(ledgers) });
		this.setState({ xrp_destroyed: ledger.xrp_destroyed });
	}

	updateMetricCards(stats) {
		if (stats && stats[0] && stats[0].metric) {
			this.setState({
				payments_count: stats[0].metric.payments_count,
				transaction_count: stats[0].metric.transaction_count,
				accounts_created: stats[0].metric.accounts_created,
				ledger_interval: stats[0].metric.ledger_interval,
			})
		}
	}

	// fetchNodes() {
	// 	fetch(`${XRPSCAN_API_URL}/topology/nodes`)
	// 		.then(handleResponse)
	// 		.then((data) => {
	// 			const { nodes } = data;
	// 			this.setState({ nodes	});
	// 		})
	// 		.catch((error) => {
	// 			this.setState({
	// 				node_error: error.errorMessage,
	// 			});
	// 		});		
	// }

	fetchValidators() {
		fetch(`${XRPSCAN_API_URL}/validatorregistry?limit=10`)
			.then(handleResponse)
			.then((data) => {
				this.setState({ validators: data||[] });
			})
			.catch((error) => {
				this.setState({
					validator_error: error.errorMessage,
				});
			});		
	}

	fetchStats() {
		fetch(`${XRPSCAN_API_URL}/statistics/transactions`)
			.then(handleResponse)
			.then((data) => {
				const { stats } = data;
				this.setState({ stats });
				this.updateMetricCards(stats);
			})
			.catch((error) => {
				this.setState({
					stats_error: error.errorMessage,
				});
			});		
	}

	render() {
		const { 
			ledgers, 
			ledger_error, 
			validators,
			validator_error, 
			ledger_index,
			payments_count,
			transaction_count,
			accounts_created,
			ledger_interval,
			tx_per_second,
			xrp_destroyed,
		} = this.state;

		// Render only error message if error occured while fetching ledgers
		if (ledger_error) {
			return <div className="error">{ledger_error}</div>
		}

		return (
			<div>
				<Helmet>
					<title>Xahau Ledger Explorer - XAHSCAN</title>
				</Helmet>
				<Row>
					<Col xs={12} md={4}>
						<Notice/>
					</Col>
					<Col xs={12} md={8}>
						<SponsorContainer />
					</Col>
				</Row>
				<Row className="d-none d-md-block">
					<Col xs={12} md={12}>
						<TransactionSummary
							ledger_index={ledger_index}
							payments_count={payments_count}
							transaction_count={transaction_count}
							accounts_created={accounts_created}
							ledger_interval={ledger_interval}
							tx_per_second={tx_per_second}
							xrp_destroyed={xrp_destroyed}
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12}>
						<BannerAd/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12}>
						<LedgerList ledgers={ledgers} error={ledger_error} />
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={6}>
						<ValidatorList validators={validators} error={validator_error} />
					</Col>
					<Col xs={12} md={6}>
						<GovernanceSummary />
					</Col>
				</Row>
			</div>
		);
	}
}

export default Home;