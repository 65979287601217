import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from 'react-bootstrap/Image';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import xummKycButton from '../../assets/images/kyc-check-xumm.svg';

/* Xumm KYC verified JSX */
const xummKycVerifiedTag = (
  <tr>
    <td>
      Verified
    </td>
    <td>
      <div>
        <Image src={xummKycButton} fluid width="86" height="16"/>
      </div>
    </td>
  </tr>
);

const XummKycTag = (props) => {
	const { address } = props;
  const [kycResult, setKycResult] = useState(<></>);

  useEffect(() => {
    fetch(`${XRPSCAN_API_URL}/account/${address}/xummkyc`)
    .then(handleResponse)
    .then((data) => {
      if (data && data.kycApproved) {
        setKycResult(xummKycVerifiedTag);
      }
    })
    .catch((error) => {});
  }, [address]);

  return kycResult;
}

XummKycTag.propTypes = {
	address: PropTypes.string.isRequired,
}

export default XummKycTag;