import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import Loading from '../common/Loading';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';

const highchartsConfig = (account, payments=[], volumes=[]) => {
  // Chart labels
  let destination = account.accountName ? account.accountName.name : account.account;
  // let destination = account;
  return {
    title: { text: `Payments to ${destination}` },
    subtitle: { text: '(Powered by xahscan.com)' },
    tooltip: { shared: true },

    // Axis definition
    xAxis: [{
      labels: { format: '{value:%b %e}' },
      title: { text: 'Time' },
      crosshair: true,
      type: 'datetime',
    }],
    yAxis: [{
      title: { text: 'Payments' },
      labels: {
        formatter: function() {
          if ( this.value >= 1000 )
            return Highcharts.numberFormat( this.value/1000, 1) + " k";
          else
            return Highcharts.numberFormat(this.value, 0);
        },
      },
      maxPadding: 0.25,
      min: 0,
    },
    {
      title: { text: 'Volume' },
      labels: {
        formatter: function() {
          if ( this.value >= 1000 * 1000 )
            return Highcharts.numberFormat( this.value/1000000, 1) + " M XAH";
          if ( this.value >= 1000 )
            return Highcharts.numberFormat( this.value/1000, 1) + " K XAH";
          else
            return Highcharts.numberFormat(this.value, 0);
        },
      },
      maxPadding: 0.25,
      min: 0,
      opposite: true,
    }],

    // Chart settings
    chart: {
      type: 'area',
      zoomType: 'x',
      panning: true,
      panKey: 'ctrl',
      scrollablePlotArea: { minWidth: 600 },
    },

    // credits: {
    //   enabled: false
    // },

    // No data settings
    lang: {
        noData: "No data available"
    },
    noData: {
        style: {
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#303030'
        }
    },

    // Plot settings
    plotOptions: {
      area: {
        pointStart: 0,
        marker: {
          enabled: false,
          symbol: 'triangle',
          radius: 2,
          states: {
            hover: { enabled: true },
          }
        }
      }
    },
    series: [
    {
      name: 'Payments',
      type: 'spline',
      tooltip: { valueSuffix: '' },
      data: payments,
    },
    {
      name: 'Volume',
      lineColor: Highcharts.getOptions().colors[1],
      color: Highcharts.getOptions().colors[2],
      fillOpacity: 0.5,
      threshold: null,
      yAxis: 1,
      tooltip: { valueSuffix: ' XAH' },
      data: volumes,
    }
    ]
  }
};

class AccountAnalysis extends React.Component {
  constructor(props) {
    super(props);
    HC_exporting(Highcharts);

    this.state = {
      account: this.props.account,
      chartConfigs: highchartsConfig(this.props.address),
      loading: false,
    }
  }

  componentDidMount() {
    this.fetchPaymentFlows();
  }

  fetchPaymentFlows() {
    this.setState({ loading: true })
    const { account } = this.state;

    fetch(`${XRPSCAN_API_URL}/account/${account.account}/payment_flows`)
      .then(handleResponse)
      .then((data) => {
        const chartConfigs = highchartsConfig(
          account,
          this.getPaymentSeries(data),
          this.getVolumeSeries(data),
          );
        this.setState({
          chartConfigs: chartConfigs,
          loading: false,
        })
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error.errorMessage,
        })        
      });
  }

  getPaymentSeries(data) {
    return data.map((date) => [new Date(date._id).getTime(), date.payments]);
  }

  getVolumeSeries(data) {
    return data.map((date) => [new Date(date._id).getTime(), date.volume]);
  }

  render() {
    const { chartConfigs, loading } = this.state;

    if (loading) {
      return <Loading />
    }

    return (
      <div>
        <br/>
        <Card className="shadow-sm">
          <Card.Header as="h6">
            Payment flows over last 30 days
          </Card.Header>
          <Card.Body>
            <HighchartsReact
              highcharts={Highcharts}
              options={chartConfigs}
            />
          </Card.Body>
        </Card>
        <Alert variant={'warning'}>
          <strong>Disclaimer: </strong>XAHSCAN provides this website, charts and
          its contents for informational purposes only. You should not construe
          any information presented here as financial, investment, tax, legal
          or other advice. Trading and investing in cryptocurrencies involves significant
          risk of loss and is not suitable for every investor. Please consult your
          financial advisor before investing.
        </Alert>
      </div>
    )
  }
}

AccountAnalysis.propTypes = {
  account: PropTypes.object.isRequired,
}

export default AccountAnalysis;