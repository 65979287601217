import React from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Badge from 'react-bootstrap/Badge';


const NexoButton = () => {
  const popover = (
    <Popover id="popover-sponsor">
      <Popover.Title>
        Buy XRP and receive crypto rewards&nbsp;
        <span className="small"><Badge variant="secondary">SPONSORED</Badge></span>
      </Popover.Title>
      <Popover.Content>
        Build your wealth effortlessly with leading rates on XRP, BTC, and 35+ more assets
      </Popover.Content>
    </Popover>
  );

  return (
    <span>
        <OverlayTrigger
          key={'bottom'}
          placement={'bottom'}
          overlay={popover}
        >
          <Button
            variant="info"
            size="sm"
            href="https://api.xrpscan.com/api/v1/campaign/nexoxrpcreditlines"
            target="_blank"
            rel="noopener nofollow"
            className="dropdown-toggle"
          >Earn 8% per year on XRP</Button>
        </OverlayTrigger>
    </span>
  )
}

export default NexoButton;
