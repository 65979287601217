import React from 'react';
import Table from 'react-bootstrap/Table';
import PropTypes from 'prop-types';
import Loading from '../common/Loading';
import TxItem from '../lib/TxItem';
import XIcon from '../lib/XIcon';
import EmptyStateNoRecord from '../lib/EmptyStateNoRecord';

const SearchResults = (props) => {
  const { data, loading, address, errorMessage } = props;
  const { transactions, count } = data;

  return(
    <div>
      <Table responsive>
        <thead>
          <tr>
            <th>Type</th>
            <th>Date</th>
            <th>Tx hash</th>
            <th>From</th>
            <th className="text-center"><XIcon icon="exchange" className="text-secondary"/></th>
            <th>To</th>
            <th className="text-right">Amount</th>
            <th className="text-center"><XIcon icon="check" className="text-secondary"/></th>
          </tr>
        </thead>
        <tbody>
          { transactions && transactions.length > 0 && transactions.map((tx) => (
            <TxItem
              key={tx.hash}
              tx={tx}
              address={address}
            />
          ))}
        </tbody>
      </Table>
      {loading &&
        <Loading/>
      }
      {errorMessage &&
      <EmptyStateNoRecord title="Error">
        {errorMessage}
      </EmptyStateNoRecord>
      }
      {!loading && transactions && count === 0 &&
			<EmptyStateNoRecord title="Not found">
				No matching transactions found – Please refine your search.
			</EmptyStateNoRecord>
      }
    </div>
  )
}

SearchResults.defaultProps = {
  data: {},
  loading: false,
  address: "",
  errorMessage: null,
}

SearchResults.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  address: PropTypes.string,
  errorMessage: PropTypes.string,
}

export default SearchResults;