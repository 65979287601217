import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Money from '../lib/Money';
import AccountTag from '../lib/AccountTag';
import XBadge from '../lib/XBadge';
import EmptyStateNoRecord from '../lib/EmptyStateNoRecord';
import Loading from '../common/Loading';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import AdvancedTable from '../lib/AdvancedTable';
import { hex2ascii } from '../common/Helpers';


const AccountTrustline = (props) => {
	const { address } = props;
	const [ trustlines, setTrustlines ] = useState([]);
	const [ frozenTrustlines, setFrozenTrustlines ] = useState({});
	const [ loading, setLoading ] = useState(false);

	const columns = useMemo(
		() => [
			{
				Header: "Trustlines",
				columns: [
				{
					Header: "#",
					accessor: "index",
				},
				{
					Header: "Counterparty (Peer)",
					accessor: "account",
					Cell: ({ cell: { value }}) => <AccountTag link>{value}</AccountTag>
				},
				{
					Header: "Token",
					accessor: "currency",
					className: "text-monospace"
				},
				{
					Header: "Peer limit",
					accessor: "limit_peer",
					className: "text-right",
				},
				{
					Header: "Owner limit",
					accessor: "limit",
					className: "text-right",
				},
				{
					Header: "Balance",
					accessor: "balance",
					className: "text-right",
				},
				{
					Header: "Rippling",
					accessor: "no_ripple",
					Cell: ({ cell: { value }}) => value ?
						<XBadge variant="primary">&nbsp;NO&nbsp;</XBadge>
						:
						<XBadge variant="success">YES</XBadge>
				},
				],
			},
			],
			[],
	)

	/*
	* Add custom properties to represent Money etc.
	*/
	const addProperties = (_trustlines) => {
		const freezeTag = <XBadge variant="danger" className="text-uppercase mr-1">FROZEN</XBadge>
		const _frozenTrustlines = {}
		const populatedTrustlines = _trustlines.map((tl, index) => {
			tl.index = index + 1
			tl.currency = (tl.currency && tl.currency.length === 40) ? hex2ascii(tl.currency) : tl.currency
			tl.balance = <Money value={tl.balance} currency={tl.currency} issuer={tl.account}/>
			tl.limit = <Money value={tl.limit} currency={tl.currency} issuer={tl.account}/>
			tl.limit = tl.freeze ? <>{freezeTag}{tl.limit}</> : tl.limit
			tl.limit_peer = <Money value={tl.limit_peer} currency={tl.currency} issuer={tl.account} />
			tl.limit_peer = tl.freeze_peer ? <>{freezeTag}{tl.limit_peer}</> : tl.limit_peer
			if (tl.freeze || tl.freeze_peer) {
				_frozenTrustlines[`${tl.account}.${tl.currency}`] = true
			}
			return tl;
		});
		setFrozenTrustlines(_frozenTrustlines);
		return populatedTrustlines;
	}

	useEffect(() => {
		setLoading(true);
		fetch(`${XRPSCAN_API_URL}/account/${address}/trustlines2`)
			.then(handleResponse)
			.then((data) => {
				setTrustlines(addProperties(data.lines));
			})
			.catch((error) => {
				setTrustlines([]);
			})
			.finally(() => {
				setLoading(false);
			});
			return () => {
				setTrustlines([]);
			}
	}, [address]);

	if (loading) {
		return <Loading/>;
	}

	if (!loading && trustlines && trustlines.length === 0) {
		return (
			<EmptyStateNoRecord title="No trustlines">
				This account has no trustlines
			</EmptyStateNoRecord>
		)
	} else {
		return <AdvancedTable
			columns={columns}
			data={trustlines}
			getRowProps={row => ({
				className: (row.values && frozenTrustlines[`${row.values.account}.${row.values.currency}`]) ? 'table-active' : '',
			})}
			filterProperty='account'
			filterPlaceholder='Search counterparty account'
		/>
	}
}

AccountTrustline.propTypes = {
	address: PropTypes.string.isRequired,
}

export default AccountTrustline;