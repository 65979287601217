import React, { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import EmptyStateNoRecord from '../lib/EmptyStateNoRecord';
import XIconBadge from '../lib/XIconBadge';
import { transferFeeToPercent } from '../common/Helpers'
import Loading from '../common/Loading';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import AdvancedTable from '../lib/AdvancedTable';
import XBadge from '../lib/XBadge';

const AccountNFToken = (props) => {
	const { address } = props;
	const [ nftokens, setNftokens ] = useState([]);
	const [ loading, setLoading ] = useState(false);

	const columns = useMemo(() => [
		{
			Header: "NFTs owned by this account",
			columns: [
				{
					Header: "#",
					accessor: "index",
				},
				{
					Header: "Type",
					accessor: "NFTokenType",
					Cell: ({ cell: { value }}) => <XBadge variant='info'>XLS-20</XBadge>
				},
				{
					Header: "NFT ID",
					accessor: "NFTokenID",
					className: "text-left text-truncate nft-truncate",
					Cell: ({ cell: { value }}) => <Link to={`/nft/${value}`}>{value}</Link>
				},
				{
					Header: "Serial",
					accessor: "nft_serial",
					className: "text-right",
					Cell: ({ cell: { value }}) => <XIconBadge variant="primary" icon="hashtag">{value}</XIconBadge>
				},
				{
					Header: "Taxon",
					accessor: "NFTokenTaxon",
					className: "text-right",
					Cell: ({ cell: { value }}) => <XIconBadge variant="success" icon="layer-group">{value}</XIconBadge>
				},
				{
					Header: "Issuer",
					accessor: "Issuer",
					className: "text-left text-truncate nft-truncate",
				},
				{
					Header: "Flags",
					accessor: "Flags",
					className: "text-left text-monospace"
				},
				{
					Header: "URI",
					accessor: "URI",
					className: "text-left text-nowrap text-truncate url-truncate",
				},
				{
					Header: "Fee",
					accessor: "TransferFee",
					className: "text-right text-monospace",
					Cell: ({ cell: { value }}) => (value && value >= 0) ? `${transferFeeToPercent(value)}%` : ''
				},
			],
		}
	],
	[]);

	/*
	* Add custom properties to NFToken objects
	*/
	const addProperties = (_nftokens) => {
		const populatedNftokens = _nftokens
		.sort((a, b) => a.nft_serial > b.nft_serial)
		.map((nft, index) => {
			nft.index = index + 1
			return nft
		})
		return populatedNftokens;
	}

	/*
	* Fetch NFTokens owned by address
	*/
	useEffect(() => {
		setLoading(true);
		fetch(`${XRPSCAN_API_URL}/account/${address}/nfts`)
		.then(handleResponse)
		.then((data) => {
			setNftokens(addProperties(data));
		})
		.catch((error) => {
			setNftokens([]);
		})
		.finally(() => {
			setLoading(false);
		})
		return () => {
			setNftokens([]);
		}
	}, [address]);

	if (loading) {
		return <Loading />;
	}

	if (!loading && nftokens && nftokens.length === 0) {
		return (
			<EmptyStateNoRecord title="No NFTs">
				This account has no NFTs
			</EmptyStateNoRecord>
		)
	} else {
		return <AdvancedTable
			columns={columns}
			data={nftokens}
			filterProperty='Issuer'
			filterPlaceholder='Filter NFTs by Issuer'
		/>
	}
}

AccountNFToken.propTypes = {
	address: PropTypes.string.isRequired,
}

export default AccountNFToken;